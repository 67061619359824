import React, { useContext, useMemo } from "react";

import { AppContext } from "../../context/app";
import { composeStrings } from "../../helpers/JSFunctions";
import { useWindowSize } from "../../hooks/screen";
import { Button } from "../Button";
import { SHOP_URL } from "../../const";

import "./style.scss";

const ShopNow = () => {
  const { shopNowData } = useContext(AppContext);
  const screen = useWindowSize();

  const subscriptionPrivileges = useMemo(() => {
    if (!shopNowData) {
      return null;
    }
    return (
      <ul className="h6 card-text mt-5 text-start ps-3">
        {shopNowData.subscriptionPrivileges.map((privilege) => (
          <li key={`privilege-${privilege}`} className="mx-sm-2 my-2">
            {privilege}
          </li>
        ))}
      </ul>
    );
  }, [shopNowData]);

  if (!shopNowData) {
    return null;
  }

  return (
    <div className="container-fluid px-0 px-sm-3">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-lg-6">
          <div className="card py-5 px-3 h-100 position-relative">
            <div className="card-body">
              <h2
                className={`text-start mb-3 card-title fs-obviously-extended ${
                  screen.width <= 575
                    ? "fs-4 paragraph-lineHeight-32"
                    : "fs-3 paragraph-lineHeight-40"
                }`}
              >
                {composeStrings(shopNowData.purchasePriceHeader)}
              </h2>
              <p className="h6 card-text my-4 text-start paragraph-lineHeight-26">
                {shopNowData.purchasePriceDescription}
              </p>
              <p className="mb-0 small fs-obviously-wide paragraph-lineHeight-24">
                {shopNowData.purchasePriceFooter}
              </p>
            </div>
            <div className="card-footer bg-transparent border-0 text-dark text-start">
              <p className="mb-1 fs-5 fs-obviously-extended paragraph-lineHeight-40 frame-price">
                {shopNowData.pricePerUnit}{" "}
                <span className="price-caption">+ shipping</span>
              </p>
              <p className="mb-3">{shopNowData.purchasePriceSubstring}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="card py-5 px-3 h-100"
            style={{ backgroundColor: "#E9EFFC" }}
          >
            <div className="card-body">
              <h2
                className={`d-flex align-items-center text-start mt-4 card-title fs-obviously-extended ${
                  screen.width <= 575
                    ? "small paragraph-lineHeight-32"
                    : "fs-6 paragraph-lineHeight-40"
                }`}
              >
                <span>+</span>
                <span className="mx-2">{shopNowData.subscriptionTitle}</span>
              </h2>
              {subscriptionPrivileges}
            </div>
            <div className="card-footer bg-transparent border-0">
              <div className="row">
                <div className="col-md-6 text-dark">
                  <p className="text-start mb-3 fs-5 fs-obviously-extended paragraph-lineHeight-40 frame-price">
                    {shopNowData.subscriptionMonthly}
                  </p>
                  <p
                    className="text-start fs-obviously-wide paragraph-lineHeight-20"
                    style={{ fontSize: 12 }}
                  >
                    Monthly
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 text-center px-md-5 px-4 position-relative"
          style={{ marginTop: "-2rem", zIndex: 1 }}
        >
          <Button
            className={"btn-enhanced-primary w-100 py-3 px-5"}
            link={SHOP_URL}
            isExternal
            text={"ORDER NOW"}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopNow;
