import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import BlogNavigation from "./BlogNavigation";
import { Button } from "../../components/Button";
import { FullDate, isEmpty } from "../../helpers/JSFunctions";

import BlogPlaceholder from "../../assets/images/blog-frame-placeholder.jpg";

import "./style.css";

const Blog = () => {
  const [Blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilter] = useState("");
  const [direction, setDirection] = useState({
    value: 0,
    cursor: "",
    state: "next",
  });

  const [PageInfo, setPageInfo] = useState();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/guest-blog") {
      setFilter("fitness");
    } else {
      setFilter("news");
    }
  }, [location.pathname]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    const handleBlogPosts = async (
      direction = "next",
      cursor = null,
      filter
    ) => {
      const API_URL =
        "https://framefitness-blogs.myshopify.com/api/2023-01/graphql.json";

      let func = `articles(first: $numBlogs, after: $cursor, reverse: true)`;
      if (direction === "previous") {
        func = `articles(last: $numBlogs, before: $cursor, reverse: true)`;
      }

      return await axios.post(
        API_URL,
        {
          query: `query ($numBlogs: Int!, $cursor: String, $filter: String!){
            blogByHandle(handle: $filter){
              ${func}{
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    cursor
                    
                    node {
                        id
                        blog {
                            id
                            title
                            handle
                        }
                        tags
                        title
                        handle
                        publishedAt
                        image {
                            url
                        }
                    }
                }}
                    }
                }`,
          variables: {
            numBlogs: 9,
            cursor: cursor || null,
            filter,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Shopify-Storefront-Access-Token":
              process.env.REACT_APP_TOKEN_SHOPIFY_BLOG,
          },
        }
      );
    };

    const timer = setTimeout(() => {
      if (filteredBlogs !== "") {
        handleBlogPosts(direction.state, direction.cursor, filteredBlogs)
          .then((response) => {
            const { data, errors } = response.data || {};
            const { blogByHandle:{articles} } = data || {};

            if (!isEmpty(articles) && articles?.edges && articles?.pageInfo) {
              const edges = articles?.edges;
              const pageData = articles?.pageInfo;

              setBlogs(edges);
              setPageInfo(pageData);
            } else {
              console.error(JSON.stringify(errors));
            }

            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
      setLoading(false);
    };
  }, [direction, filteredBlogs]);

  return (
    <div className="container-fluid my-5">
      <div className="row">
        <div className="col-12">
          <BlogNavigation
            filter={location.pathname === "/guest-blog" ? "guest" : ""}
          />
        </div>
        <div className="col-12">
          <div className="container-xl my-5">
            <div className="row g-4 align-items-stretch">
              {loading && (
                <div className="col-12 text-center">
                  <div className="spinner-border text-muted"></div>
                </div>
              )}
              {Blogs.length > 0 && (
                <>
                  {Blogs.map((value, index) => {
                    const { node } = value;
                    const { url } = node?.image || "";
                    const { blog } = node || {};

                    return (
                      <div key={index} className="col-12 col-sm-6 col-lg-4">
                        <BlogCard
                          id={blog?.handle || ""}
                          handle={node.handle}
                          title={node.title}
                          image={url}
                          filter={
                            location.pathname === "/guest-blog" ? "guest" : ""
                          }
                          date={node.publishedAt}
                          tags={node.tags}
                        />
                      </div>
                    );
                  })}
                  {PageInfo && (
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-center">
                        {PageInfo?.hasPreviousPage && (
                          <Button
                            text="Prev"
                            onClick={() => {
                              setDirection((prev) => ({
                                value: prev.value - 1,
                                cursor: Blogs[0]?.cursor || null,
                                state: "previous",
                              }));
                            }}
                            className={"primary py-2 mx-2"}
                            transparent={true}
                          />
                        )}
                        {PageInfo?.hasNextPage && (
                          <Button
                            text="Next"
                            onClick={() => {
                              setDirection((prev) => ({
                                value: prev.value + 1,
                                cursor: Blogs[Blogs.length - 1]?.cursor || null,
                                state: "next",
                              }));
                            }}
                            className={"primary py-2 mx-2"}
                            transparent={true}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BlogCard = ({
  id = "",
  handle = "",
  title = "",
  image = "",
  date = "",
  filter = "",
  tags = [],
}) => {
  return (
    <Link
      to={
        filter !== ""
          ? `/${filter}-blog/${id}/${handle}`
          : `/blog/${id}/${handle}`
      }
      className="text-decoration-none text-dark"
    >
      <div className="card border-0 rounded-0 h-100 justify-content-between">
        <div className="card-header px-0 bg-transparent border-0">
          <h1
            className="card-title h5 mb-1 
                        fw-medium blog-title-clamp fw-medium"
          >
            {title}
          </h1>
          <p className="card-subtitle small"> {FullDate(date)} </p>
        </div>
        <img
          className="card-img py-3"
          src={image || BlogPlaceholder}
          alt={title || "Blog Post"}
          style={{ objectFit: "cover" }}
        />
        <div className="card-footer bg-transparent border-0 px-0">
          <p className="btn btn-outline-secondary py-3 btn-sm rounded-0 w-100">
            Read Post
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Blog;
