import React from 'react';

export const initialExperimentsState = {
  '100462802': {
    name: 'FRF | test experiment',
    variationA: '1004154626',
    activeVariation: 0
  },
};

export const ExperimentsContext = React.createContext(initialExperimentsState);
