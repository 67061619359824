import React, { useContext } from 'react';

import { useWindowSize } from '../../hooks/screen';
import { BackgroundVideoPlayer } from '../../components/BackgroundVideoPlayer';
import { HeadingBanner } from '../../components/Banner';
import { MainComponent } from '../../components/MainComponent';
import { ShopNow } from '../../components/ShopNow';
import { GradientBorder } from '../../components/GradientBorder';
import { AppContext } from '../../context/app';
import { composeStrings } from '../../helpers/JSFunctions';
import { FrameFeatures } from '../../components/FrameFeatures/FrameFeatures';
import { CarouselExtended } from '../../components/CarouselExtended';
import { HorizontalScroll } from '../../components/HorizontalScroll';
import { ExperimentsContext } from '../../experiments/context';

import './style.css';

const reformerDataExp007 = [
  {
    id: 0,
    image: 'https://conversionadvocates.com/exp-images/frf/007/slide-1.jpg',
    title: 'Compact Design, Maximum Convenience',
    description:
      'Our foldable Pilates Reformer fits seamlessly into small spaces. Perfect for urban living or your designated gym space with a fold-and-store design. Dimensions: 94" L x 25.5" W x 13" H.',
  },
  {
    id: 1,
    image: '//images.ctfassets.net/vu25xyvr5gnb/1K7dq9KGJPQKEFXvtJzANr/d5009b78bc35bbc7d5ff8d41191945aa/D1.jpg',
    title: 'Effortless Resistance Adjustment',
    description:
      'Elevate your workout with our push-button technology. Forget manual spring adjustments—enjoy easy, precise resistance control at your fingertips.',
  },
  {
    id: 2,
    image: 'https://conversionadvocates.com/exp-images/frf/007/slide-3.jpg',
    title: 'Unparalleled Strength and Mobility',
    description:
      'Durability meets portability in our top-quality Frame Reformer. With an easy-to-use carrying handle, it’s as mobile as it is robust.',
  },
  {
    id: 3,
    image: 'https://conversionadvocates.com/exp-images/frf/007/slide-4.jpg',
    title: 'Personal Pilates Studio, At Home',
    description:
      'Custom-tailored workouts await. Filter by method, duration, target areas, difficulty level, and equipment. Our system evolves with your fitness journey, ensuring a personalized experience.',
  },
  {
    id: 4,
    image: '//images.ctfassets.net/vu25xyvr5gnb/6n6E5G0kIxeHoLMbQRI6a2/c07dbeebea1f21102aef73bddb6050d6/D2.jpg',
    title: 'Transformative Touchscreen Technology',
    description:
      'Our 24" collapsible, Bluetooth-enabled touchscreen revolutionizes your workouts. Enjoy its 180° swivel feature for diverse exercises, and the ability to fold down the screen for neat storage.',
  },
];

const Reformer = () => {
  const { reformerData } = useContext(AppContext);
  const screen = useWindowSize();

  const reformersExp007 = reformerDataExp007.map((item) => <HorizontalScroll key={item.id} {...item} isExp007={true} />);
  const frf007Id = '100462802';
  const experiments = useContext(ExperimentsContext);
  const isExp007 = experiments[frf007Id].activeVariation === 1;

  if (!reformerData) {
    return null;
  }

  return (
    <>
      <HeadingBanner
        bannerImage={reformerData.headerImage.file.url}
        text={composeStrings(reformerData.header, `${screen.width > 2560 ? 'd-none' : 'd-block'}`)}
        gradient={'linear-gradient(0deg, #FFCAB9 0%, #99DBAF 100%)'}
        className={'reformer-background'}
      />

      {isExp007 && (
        <div className="carousel-new px-sm-5 px-0">
          <h2 className="text-dark fs-obviously-extended h2 px-3 px-sm-0">Redefining Pilates: Beyond Traditional Reformers</h2>
          <CarouselExtended isExp007={true}>{reformersExp007}</CarouselExtended>
        </div>
      )}

      <div className="row g-0 align-items-stretch justify-content-between">
        <div className="col-12 text-center overflow-hidden">
          <div className="position-relative">
            <BackgroundVideoPlayer url={reformerData.videoBlock1BestPilates.file.url} width={'100%'} height={'100%'} />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light py-5 position-relative">
        <GradientBorder width={screen.width < 600 ? 25 : 45} className="h-100" />
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 my-sm-5 my-0 py-5">
              <MainComponent
                difference={200}
                title={composeStrings(reformerData.reformerBlock1Header, `${screen.width > 480 && screen.width <= 768 ? 'd-none' : 'd-block'}`)}
                description={reformerData.reformerBlock1Description}
                imageSource={reformerData.reformerBlock1Image.file.url}
                imageTitle={reformerData.reformerBlock1Image.title}
                background={`linear-gradient(0deg, #FFCAB9 0%, #99DBAF 100%)`}
              />
            </div>
            <div className="col-12 my-sm-5 my-0 py-5">
              <MainComponent
                difference={100}
                title={composeStrings(reformerData.reformerBlock2Header, `${screen.width > 480 && screen.width <= 768 ? 'd-none' : 'd-block'}`)}
                description={reformerData.reformerBlock2Description}
                imageSource={reformerData.reformerBlock2Image.file.url}
                imageTitle={reformerData.reformerBlock2Image.title}
                background={`linear-gradient(0deg, #85A3F1 0%, #FFB69F 100%)`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 align-items-stretch justify-content-between">
        <div className="col-12 text-center overflow-hidden">
          <div className="position-relative">
            <BackgroundVideoPlayer url={reformerData.videoBlock2.file.url} width={'100%'} height={'100%'} />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light py-5 position-relative">
        <GradientBorder width={screen.width < 600 ? 25 : 45} className="h-100" />
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 my-sm-5 my-0 py-5">
              <MainComponent
                difference={100}
                isReversed={true}
                title={composeStrings(reformerData.reformerBlock3Header, `${screen.width > 480 && screen.width <= 768 ? 'd-none' : 'd-block'}`)}
                description={reformerData.reformerBlock3Description}
                imageSource={reformerData.reformerBlock3Image.file.url}
                imageTitle={reformerData.reformerBlock3Image.title}
                background={`linear-gradient(0deg, #DFFF61 0%, #D9B2FF 100%)`}
              />
            </div>
            <div className="col-12 my-sm-5 my-0 py-5">
              <MainComponent
                difference={100}
                isReversed={true}
                title={composeStrings(reformerData.reformerBlock4Header, `${screen.width > 480 && screen.width <= 768 ? 'd-none' : 'd-block'}`)}
                description={reformerData.reformerBlock4Description}
                imageSource={reformerData.reformerBlock4Image.file.url}
                imageTitle={reformerData.reformerBlock4Image.title}
                background={`linear-gradient(0deg, #FFCAB9 0%, #99DBAF 100%)`}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid position-relative py-sm-0 pb-5"
        style={{
          backgroundImage: 'linear-gradient(180deg, #E6E1DB 37.76%, #FAAA8F 99.34%)',
        }}
      >
        <div className="row align-items-stretch justify-content-between" style={{ overflowX: 'hidden' }}>
          <div className="col-12 position-relative overflow-hidden text-center fit-in-your-space">
            <img
              src={reformerData.sizeBlockImage.file.url}
              className={`img-fluid position-absolute ${
                screen.width >= 576 ? 'top-0 start-50 translate-middle-x' : ' top-50 start-50 translate-middle'
              }`}
              alt={'Hero Background'}
            />
          </div>
        </div>
        <div
          className={`${screen.width >= 576 ? 'position-absolute top-100 start-50 translate-middle' : 'w-100'}`}
          style={{ width: screen.width <= 1680 ? '90%' : '65%' }}
        >
          <div className={`card p-xl-5 p-4 ${screen.width < 576 ? 'border-0 rounded-0 m-0' : 'mb-3'}`}>
            <div className="row g-0 align-items-center">
              <div className="col-md-5">
                <h2 className={`text-start mb-3 card-title fs-obviously-extended ${screen.width <= 768 ? 'fs-5' : 'fs-3'}`}>
                  {reformerData.sizeBlockTitle}
                </h2>
              </div>
              <div className="col-md-7">
                <div className="card-body p-md-3 p-0">
                  <div className="row g-0 my-4">
                    <div className="col-lg-6 pe-lg-1">
                      <p
                        className="text-start fs-obviously-wide mb-0 paragraph-lineHeight-24"
                        style={{
                          fontSize: screen.width <= 1024 && screen.width >= 992 ? 14 : screen.width < 576 ? 14 : 16,
                        }}
                      >
                        {reformerData.sizeBlockSizeDisplayed}
                      </p>
                      <p
                        className="text-start fs-obviously-wide paragraph-lineHeight-24"
                        style={{
                          fontSize: screen.width <= 1024 && screen.width >= 992 ? 11 : screen.width < 576 ? 11 : 12,
                        }}
                      >
                        Dimensions w/ Screen Displayed
                      </p>
                    </div>
                    <div className="col-lg-6 ps-lg-1">
                      <p
                        className="text-start fs-obviously-wide mb-0 paragraph-lineHeight-24"
                        style={{
                          fontSize: screen.width <= 1024 && screen.width >= 992 ? 14 : screen.width < 576 ? 14 : 16,
                        }}
                      >
                        {reformerData.sizeBlockSizeStored}
                      </p>
                      <p
                        className="text-start fs-obviously-wide paragraph-lineHeight-24"
                        style={{
                          fontSize: screen.width <= 1024 && screen.width >= 992 ? 11 : screen.width < 576 ? 11 : 12,
                        }}
                      >
                        Dimensions w/ Screen Stored
                      </p>
                    </div>
                  </div>
                  <p className="card-text paragraph-lineHeight-26">{reformerData.sizeBlockDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid pt-sm-5 pt-0"
        style={{
          backgroundImage: 'linear-gradient(4deg, #ACB1C5 0%, #E1E3EC 100%)',
        }}
      >
        <div className="row align-items-stretch justify-content-center px-xl-5">
          <FrameFeatures />
          <div className="col-12 py-5" style={{ marginBottom: 150 }}>
            <ShopNow />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reformer;
