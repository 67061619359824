import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Interweave from "interweave";
import axios from "axios";

import BlogNavigation from "./BlogNavigation";
import { SubscriptionForm } from "./../../components/SubscriptionForm";
import { HelmetComponent } from "../../components/Helmet";
import { Button } from "../../components/Button";
import { DecodeBase64, FullDate, isEmpty } from "../../helpers/JSFunctions";

import BlogPlaceholder from "../../assets/images/blog-frame-placeholder.jpg";

import "./style.css";

const BlogDetail = () => {
  const content = useParams();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState({});

  const [blog, setBlogInformation] = useState({});
  useEffect(() => {
    if (content.id && content.handle) {
      try {
        setBlogInformation({
          blog_handle: content.id,
          handle: content.handle,
        });
      } catch (error) {
        console.error(error);
        setBlogInformation({});
      }
    }
  }, [content.id, content.handle]);

  useEffect(() => {
    if (article.id) {
      try {
        let _id = DecodeBase64(article.id);
        const articleID = _id.substring(_id.lastIndexOf("/") + 1) || "";
        setBlogInformation((prevState) => ({
          ...prevState,
          article_id: articleID,
        }));
      } catch (error) {
        console.error(error);
        setBlogInformation({});
      }
    }
  }, [article.id]);

  const API_URL =
    "https://framefitness-blogs.myshopify.com/api/2023-01/graphql.json";

  useEffect(() => {
    const handleBlogPost = async (blog_handle = "", article_title = "") => {
      return await axios.post(
        API_URL,
        {
          query: `query ($handle: String!, $title: String!) {
                    blog(handle: $handle) {
                        articleByHandle(handle: $title) {
                            id
                            handle
                            tags
                            title
                            publishedAt
                            contentHtml
                            seo {
                                description
                                title
                            }
                            image {
                                url
                            }
                            blog {
                                id
                            }
                        }
                    }
                }`,
          variables: {
            handle: blog_handle,
            title: article_title,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Shopify-Storefront-Access-Token":
              process.env.REACT_APP_TOKEN_SHOPIFY_BLOG,
          },
        }
      );
    };

    if (isEmpty(article) && blog.blog_handle && blog.handle) {
      setLoading(true);
      handleBlogPost(blog.blog_handle, blog.handle)
        .then((response) => {
          const { data, errors } = response.data || {};
          const { blog } = data || {};

          if (!isEmpty(blog) && blog?.articleByHandle) {
            const fullArticle = blog?.articleByHandle || {};
            setArticle(fullArticle);
            setBlogInformation((prevState) => ({
              ...prevState,
              id: fullArticle["blog"].id,
            }));
          } else {
            console.error(JSON.stringify(errors));
          }

          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

    return () => {
      setLoading(false);
    };
  }, [blog.blog_handle, blog.handle, article]);

  const transform = (node, children) => {
    const { className, tagName, classList } = node || null;
    if (className && className === "shopify-subscription-form") {
      return <SubscriptionForm />;
    }

    if (tagName && tagName === "IMG") {
      if (!classList.contains("img-fluid")) {
        const { src, alt } = node || null;
        return (
          <img
            className={`img-fluid text-center my-2 ${!src && "d-none"}`}
            src={src}
            alt={alt || "Frame Blog Article Image"}
          />
        );
      }
    }
  };

  const fallbackTags =
    "frame fitness, frame fitness blog, how to become a best version of myself, learn new exercises, pilates reformer, pilates reformer exercises, frame fitness guest blog, learn new exercises, pilates reformer, pilates reformer exercises";

  return (
    <div className="container-fluid my-5">
      <div className="row">
        <div className="col-12">
          <BlogNavigation
            filter={location.pathname.startsWith("/guest-blog") ? "guest" : ""}
          />
        </div>
        <div className="col-12">
          <div className="container-xl my-5">
            <div className="row g-3 align-items-stretch">
              {loading && (
                <div className="col-12 text-center">
                  <div className="spinner-border text-muted"></div>
                </div>
              )}

              {!isEmpty(article) && (
                <>
                  <HelmetComponent
                    metaData={{
                      link: window.location.pathname,
                      name:
                        article["seo"]?.title ||
                        `${article["title"]} | Frame Fitness Blog`,
                      title: `${article["title"]} | Frame Fitness Blog`,
                      description: article["seo"]?.description,
                      image: article["image"]?.url || BlogPlaceholder,
                      keywords: article["tags"] || fallbackTags,
                      type: "article",
                    }}
                  />
                  <div className="col-12 text-center">
                    <span className="blog-details-date small mb-1">
                      {FullDate(article["publishedAt"] || "")}
                    </span>
                  </div>
                  <div className="col-12 text-center">
                    <h1 className="fs-3 fw-bold">{article["title"]} </h1>
                  </div>
                  <div className="col-12 text-center">
                    <img
                      src={article["image"]?.url || BlogPlaceholder}
                      className="img-fluid w-100"
                      alt="Frame Logo"
                      style={{
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <Interweave
                      content={
                        article["contentHtml"] || "<p> No Data Found!! </p>"
                      }
                      transform={transform}
                    />
                  </div>
                  <div className="col-12">
                    <Button
                      className="secondary d-inline-block"
                      icon={
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 18 28"
                          fill="none"
                        >
                          <path
                            d="M15.6057 3L5 13.6057L15.6057 24.2114"
                            stroke="#6c757d"
                            strokeWidth="6"
                          />
                        </svg>
                      }
                      link={
                        location.pathname.startsWith("/guest-blog")
                          ? "/guest-blog"
                          : "/blog"
                      }
                      text={"Back to blog"}
                      transparent={true}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
