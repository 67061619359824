import React, { useContext } from "react";
import ReactCompareImage from "react-compare-image";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

import { AppContext } from "../../context/app";
import { Button } from "../Button";

const ProductCompare = () => {
  const { homePageData } = useContext(AppContext);

  const CompareButtons = (
    <Button
      className={`px-1 py-1 btn-enhanced-light rounded-0 text-center`}
      style={{
        cursor: "w-resize",
      }}
      text=""
      icon={
        <span className="d-flex align-items-center justify-content-center">
          <GoChevronLeft
            size={"1.75em"}
            color="#245ae5"
            className="fw-bold"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <GoChevronRight
            size={"1.75em"}
            color="#245ae5"
            className="fw-bold"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </span>
      }
    />
  );
  return (
    <div className="row g-0 justify-content-between align-items-center">
      <div className="col-12 text-center p-0">
        {homePageData && (
          <ReactCompareImage
            aspectRatio={"wider"}
            handle={CompareButtons}
            hover={true}
            data-id="compare-image"
            leftImage={homePageData.designSlider[0].file.url}
            leftImageAlt={"Sunrise Pilates Reformer"}
            rightImage={homePageData.designSlider[1].file.url}
            rightImageAlt={"Midnight Pilates Reformer"}
          />
        )}
      </div>
    </div>
  );
};

export default ProductCompare;
