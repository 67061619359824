import React, { useContext } from "react";

import { parsePolicy } from "../../helpers/parsePolicy";
import { AppContext } from "../../context/app";

import "./style.css";

export const Policy = () => {
  const { globalData } = useContext(AppContext);

  if (!globalData) return null;

  const pathname = window.location.pathname.replaceAll("/", "");

  const Paths = {
    "terms-of-service": globalData.rules.filter(
      (rule) => rule.id === "Terms of Service"
    )[0],
    "privacy-policy": globalData.rules.filter(
      (rule) => rule.id === "Privacy Policy"
    )[0],
    "return-policy": globalData.rules.filter(
      (rule) => rule.id === "Return Policy"
    )[0],
    warranty: globalData.rules.filter((rule) => rule.id === "Warranty")[0],
  };

  return (
    <div className="policy-wrapper">
      <div className="policy-content">{parsePolicy(Paths[pathname])}</div>
    </div>
  );
};
