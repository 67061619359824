import React, { useContext, useEffect, useMemo, useState } from "react";

import { useWindowSize } from "../../hooks/screen";
import { Button } from "../Button";

import "./style.css";
import { AppContext } from "../../context/app";

export const FrameFeatures = () => {
  const { reformerData } = useContext(AppContext);
  const screen = useWindowSize();
  const [footerCarousel, setFooterContent] = useState({
    title: "",
    description: "",
  });

  const footerValues = useMemo(
    () => [
      {
        title: reformerData?.infoBlockGeneralTitle || "",
        description: reformerData?.infoBlockGeneralDescription || "",
      },
      {
        title: reformerData?.infoBlockResistanceTitle || "",
        description: reformerData?.infoBlockResistanceDescription || "",
      },
      {
        title: reformerData?.infoBlockPatentTitle || "",
        description: reformerData?.infoBlockPatentDescription || "",
      },
      {
        title: reformerData?.infoBlockCarriageTitle || "",
        description: reformerData?.infoBlockCarriageDescription || "",
      },
      {
        title: reformerData?.infoBlockTouchscreenTitle || "",
        description: reformerData?.infoBlockTouchscreenDescription || "",
      },
      {
        title: reformerData?.infoBlockFrameTitle || "",
        description: reformerData?.infoBlockFrameDescription || "",
      },
    ],
    [reformerData]
  );

  useEffect(() => {
    if (reformerData) {
      setFooterContent(footerValues[0]);
    }
  }, [footerValues, reformerData]);

  if (!reformerData) {
    return null;
  }

  const handleCarouselValues = (value) => {
    if (footerCarousel.title === footerValues[value].title) {
      setFooterContent(footerValues[0]);
    } else {
      setFooterContent(footerValues[value]);
    }
  };

  return (
    <>
      <div className="col-12 mt-sm-5 mb-5 py-5 position-relative">
        <div className="container mt-sm-5 mb-5 top-0 position-absolute start-50 translate-middle-x">
          <div className="row mt-sm-5 mb-5 pt-sm-5 pb-5">
            <div className="col-xl-7 col-lg-6 col-md-2 d-md-none d-block"></div>
            <div className="col-xl-5 col-lg-6 col-md-8">
              <h2
                className={`text-start mb-4 mt-5 ${
                  screen.width >= 576 ? "fs-3" : "fs-4"
                } fs-obviously-extended paragraph-lineHeight-36`}
              >
                {" "}
                {footerCarousel.title}{" "}
              </h2>
              <p
                className={`text-dark ${screen.width >= 576 ? "fs-5" : "fs-6"}`}
              >
                {footerCarousel.description}
              </p>
            </div>
            <div className="col-xl-8 col-lg-6 col-md-2 d-md-block d-none"></div>
          </div>
        </div>
      </div>
      <div className="col-12 px-0 text-center frame-features">
        <div
          className="blue-grad-girl"
          style={{
            backgroundImage: `url(${reformerData.infoBlockImage.file.url})`,
          }}
        >
          <div className="position-absolute frame-feature-01">
            <ReformerFeatures
              currentItemValid={footerCarousel.title === footerValues[1].title}
              onClick={() => {
                handleCarouselValues(1);
              }}
            />
          </div>
          <div className="position-absolute frame-feature-02">
            <ReformerFeatures
              currentItemValid={footerCarousel.title === footerValues[2].title}
              onClick={() => {
                handleCarouselValues(2);
              }}
            />
          </div>
          <div className="position-absolute frame-feature-03">
            <ReformerFeatures
              currentItemValid={footerCarousel.title === footerValues[3].title}
              onClick={() => {
                handleCarouselValues(3);
              }}
            />
          </div>
          <div className="position-absolute frame-feature-04">
            <ReformerFeatures
              currentItemValid={footerCarousel.title === footerValues[4].title}
              onClick={() => {
                handleCarouselValues(4);
              }}
            />
          </div>
          <div className="position-absolute frame-feature-05">
            <ReformerFeatures
              currentItemValid={footerCarousel.title === footerValues[5].title}
              onClick={() => {
                handleCarouselValues(5);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ReformerFeatures = ({ onClick, currentItemValid = false }) => {
  const screen = useWindowSize();
  return (
    <Button
      cType="icon"
      onClick={onClick}
      className={"position-relative"}
      style={{
        backgroundColor: currentItemValid ? "#ffffff" : "#DFFF61",
      }}
      transparent={true}
      size={screen.width <= 600 ? "sm" : ""}
      icon={currentItemValid ? <MinusIcon /> : <PlusIcon />}
    />
  );
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
    >
      <g>
        <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="#000000" />
      </g>
    </svg>
  );
};

const MinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};
