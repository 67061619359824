import React, { useContext } from "react";

import { PressCarousel } from "../../components/PressCarousel";
import { AppContext } from "../../context/app";
import { useWindowSize } from "../../hooks/screen";

const Press = () => {
  const { pressPageData } = useContext(AppContext);
  const screen = useWindowSize();

  if (!pressPageData) {
    return null;
  }

  return (
    <div className="container container-fluid">
      <div className="row align-items-center justify-content-center py-2 my-2">
        <div className="col-12 py-5 my-5">
          <PressCarousel
            title={
              <h1
                className={`text-dark fs-obviously-extended paragraph-lineHeight-40 ${
                  screen?.width <= 567 ? "h4" : "h3"
                }`}
              >
                {pressPageData.header}
              </h1>
            }
            seeMore={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Press;
