import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { SideElements } from "./components/SideElements";

import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";

import { Home } from "./pages/Home";
import { Reformer } from "./pages/Reformer";
import { About } from "./pages/About";
import { Workout } from "./pages/Workout";
import { Blog, BlogDetail } from "./pages/Blog";
import { Press } from "./pages/Press";
import { Policy } from "./components/Policy/Policy";

import { SHOP_URL } from "./const";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" component={NavWrapping(Home)} exact />
      <Route path="/reformer" component={NavWrapping(Reformer)} exact />
      <Route path="/workout" component={NavWrapping(Workout)} exact />
      <Route path="/about" component={NavWrapping(About)} exact />

      <Route
        path="/shop"
        component={() => {
          window.location.href = SHOP_URL;
        }}
        exact
      />

      <Route path="/press" component={NavWrapping(Press)} exact />
      <Route path="/blog" component={NavWrapping(Blog)} exact />
      <Route path="/guest-blog" component={NavWrapping(Blog)} exact />
      <Route
        path="/blog/:id/:handle"
        component={NavWrapping(BlogDetail)}
        exact
      />
      <Route
        path="/guest-blog/:id/:handle"
        component={NavWrapping(BlogDetail)}
        exact
      />

      <Route path="/terms-of-service" component={NavWrapping(Policy)} exact />
      <Route path="/privacy-policy" component={NavWrapping(Policy)} exact />
      <Route path="/return-policy" component={NavWrapping(Policy)} exact />
      <Route path="/warranty" component={NavWrapping(Policy)} exact />

      <Redirect path="*" to={"/"} />
    </Switch>
  );
};

const NavWrapping = (WrappingComponent) => {
  const NavWrapper = () => {
    return (
      <>
        <SideElements>
          <Navbar />
          <main>
            <WrappingComponent />
          </main>
          <Footer />
        </SideElements>
      </>
    );
  };

  return NavWrapper;
};

export default Routes;
