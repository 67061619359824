import React, { useContext, useMemo } from "react";

import { useWindowSize } from "../../hooks/screen";
import { AboutBanner } from "../../components/Banner";
import { AppContext } from "../../context/app";
import { composeStrings } from "../../helpers/JSFunctions";
import { Button } from "../../components/Button";
import { SHOP_URL } from "../../const";

const About = () => {
  const { aboutPageData } = useContext(AppContext);
  const screen = useWindowSize();

  const investors = useMemo(() => {
    if (!aboutPageData) return null;
    return aboutPageData.investors.map((investor) => (
      <div
        key={investor.id}
        className={`${
          screen.width >= 1600 ? "col-xl-6" : "col-xl-4"
        } col-lg-6 p-md-5 px-3`}
      >
        <h2 className={`text-dark fs-obviously h6 paragraph-lineHeight-24`}>
          {investor.name} <br /> {investor.role}
        </h2>
        <p className={`text-dark fs-6 paragraph-lineHeight-26`}>
          {investor.description}
        </p>
      </div>
    ));
  }, [aboutPageData, screen.width]);

  if (!aboutPageData) return null;

  return (
    <>
      <AboutBanner screen={screen} />
      <div className={`${screen.width >= 1600 ? "container-xl" : "px-0"}`}>
        <div
          className={`${
            screen.width >= 1600 ? "" : "gx-0"
          } row align-items-center`}
        >
          <div
            className={`${
              screen.width >= 1600 ? "col-xl-10" : "col-xl-8"
            } col-lg-9 position-relative`}
          >
            <img
              src={aboutPageData.coFounderImage.file.url}
              alt={aboutPageData.coFounderImage.title}
              className={`img-fluid`}
            />

            <div
              className={`card border-0 ${
                screen.width >= 992
                  ? "position-absolute top-50 start-100 translate-middle"
                  : "mx-auto"
              }`}
              style={{
                width: screen.width <= 600 ? "100%" : 480,
                marginTop:
                  screen.width <= 991 && screen.width >= 600 ? "-100px" : "",
                backgroundImage:
                  "linear-gradient(180deg, #ECD8FF 0%, #FFFFFF 100%)",
              }}
            >
              <div className="card-body p-sm-5 p-3">
                <h2 className="card-title fs-obviously-extended fs-6 py-3 paragraph-lineHeight-26">
                  {composeStrings(aboutPageData.coFounder1Title)}
                </h2>
                <p className="card-text paragraph-lineHeight-26">
                  {aboutPageData.coFounder1Description}
                </p>
                <h2 className="card-title fs-obviously-extended fs-6 py-3 paragraph-lineHeight-26">
                  {composeStrings(aboutPageData.coFounder2Title)}
                </h2>
                <p className="card-text paragraph-lineHeight-26">
                  {aboutPageData.coFounder2Description}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 container-fluid">
            <div className="row align-items-center py-md-5 py-3 gx-0">
              {investors}
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-5"
        style={{
          backgroundImage: "linear-gradient(0deg, #EDEDED 0%, #D9B2FF 100%)",
        }}
      >
        <div className="row">
          <div className="col-12 text-center px-sm-5 px-3 pb-5">
            <h2
              className={`text-uppercase text-dark py-5 px-sm-5 px-3 fs-obviously-extended paragraph-lineHeight-40 ${
                screen.width <= 567 ? "h5" : "fs-1"
              }`}
            >
              Get Moving
            </h2>
            <Button
              text="ORDER NOW"
              link={SHOP_URL}
              isExternal
              className={"btn-enhanced-primary px-5 py-3"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
