import React, { useRef } from 'react';
import Carousel from 'react-multi-carousel';

import { useWindowSize } from '../../hooks/screen';
import { Button } from '../Button';

import 'react-multi-carousel/lib/styles.css';

const CarouselExtended = ({ children, link = '/reformer', buttonText = 'explore the reformer', isExp007 = false }) => {
  const mainButtonPosition = useRef(null);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const screen = useWindowSize();
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    return (
      <div
        className="carousel-button-group carousel-custom-buttons position-absolute"
        style={{
          left: screen.width >= 1280 ? '-5%' : screen.width <= 768 ? (screen.width <= 575 ? '10%' : '-15%') : '-10%',
          top: screen.width <= 575 ? '2%' : '0',
        }}
      >
        <Button
          onClick={() => previous()}
          disabled={currentSlide === 0}
          className={`d-sm-block me-2 mb-2  ${screen.width <= 575 ? 'btn-enhanced-light btn-enhanced-raised ' : ''}`}
          cType={'icon'}
          transparent={screen.width > 575}
          icon={
            <svg width="28" height="28" viewBox="0 0 40 40" fill="none">
              <rect width="28" height="28" />
              <path d="M23.1865 10L13.5449 19.6416L23.1865 29.2831" stroke="#245AE5" strokeWidth="5" strokeLinecap="round" />
            </svg>
          }
          size={screen.width > 575 ? 'lg' : ''}
        />
        <Button
          onClick={next}
          disabled={currentSlide === totalItems - slidesToShow}
          className={`d-sm-block me-2 mb-2  ${screen.width <= 575 ? 'btn-enhanced-raised btn-enhanced-light' : ''}`}
          cType={'icon'}
          transparent={screen.width > 575}
          size={screen.width > 575 ? 'lg' : ''}
          icon={
            <svg width="28" height="28" viewBox="0 0 40 40" fill="none">
              <rect width="28" height="28" />
              <path d="M16.8135 10L26.4551 19.6416L16.8135 29.2831" stroke="#245AE5" strokeWidth="5" strokeLinecap="round" />
            </svg>
          }
        />
      </div>
    );
  };

  return (
    <div className={`row align-items-stretch gx-0 py-5 ${isExp007 ? 'carousel-inner' : 'py-5'}`}>
      <div className={`col-12 ${isExp007 ? 'col-sm-2 col-md-1' : 'col-sm-3'} text-center`}>
        <div ref={mainButtonPosition}></div>
      </div>
      <div className={`col-12 ${isExp007 ? 'col-sm-10 col-md-11' : 'col-sm-9'} position-relative pe-0`}>
        <Carousel
          responsive={responsive}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          shouldResetAutoplay={false}
          draggable={false}
          swipeable={false}
          autoPlay={false}
        >
          {children}
        </Carousel>
      </div>
      {!isExp007 && (
        <div className="col-12 text-center my-5">
          <Button className={`btn-enhanced-dark py-3 px-5 mx-sm-0 mx-3`} link={link} text={buttonText} />
        </div>
      )}
    </div>
  );
};

export default CarouselExtended;
