import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";

import { TextExplosion } from "../../components/TextExplosion";
import { AnimationContext } from "../../context/animationContext";
import { AppContext } from "../../context/app";
import { hasElementExitsViewPort } from "../../hooks/ObserveElement";
import { CustomizeHeading } from "../Heading";

import "./style.css";

export const MainBanner = ({ screen, bannerImage = "", children }) => {
  const { setAnimation } = useContext(AnimationContext);
  const bannerRef = useRef(null);
  const textReference = useRef(null);

  useLayoutEffect(() => {
    let element = bannerRef?.current;

    if (element) {
      const isElementInView = () => {
        if (hasElementExitsViewPort(element)) {
          setAnimation(true);
        }
      };

      window.addEventListener("scroll", isElementInView);
      return () => {
        setAnimation(false);
        window.removeEventListener("scroll", isElementInView);
      };
    }
  }, [setAnimation]);

  const [viewStatus, setViewStatus] = useState(true);

  const handleOnLeave = () => {
    setViewStatus(true);
  };

  const handleOnEntered = () => {
    setViewStatus(false);
  };

  return (
    <div
      className="row align-items-stretch justify-content-between gx-0"
      ref={bannerRef}
    >
      <div className="col-12 overflow-hidden">
        <div
          className="card border-0"
          style={{
            backgroundImage:
              "linear-gradient(rgb(230, 225, 219) 37.76%, rgb(250, 170, 143) 99.34%)",
          }}
        >
          <div
            className="card-header border-0 landing-page-banner-image"
            style={{ backgroundImage: `url(${bannerImage})` }}
          >
            <Waypoint
              topOffset={"40%"}
              bottomOffset={"35%"}
              onLeave={handleOnLeave}
              onEnter={handleOnEntered}
            >
              <div className="position-absolute start-50 translate-middle w-100 h-50 top-50 ">
                <h1
                  ref={textReference}
                  className={`fs-obviously-extended text-uppercase made-for-motion text-center w-100 h-100 px-md-3 ${
                    screen.width > 768
                      ? "display-5"
                      : screen.width > 600
                      ? "fs-1"
                      : "fs-2"
                  }`}
                >
                  <TextExplosion
                    text="made for motion"
                    reference={textReference}
                    startAnimation={viewStatus}
                  />
                </h1>
              </div>
            </Waypoint>
          </div>
        </div>
      </div>
      {children ? children : null}
    </div>
  );
};

export const HeadingBanner = ({
  children,
  bannerImage = "",
  text = "",
  gradient = "",
  className = "reformer-background",
}) => {
  const { setAnimation } = useContext(AnimationContext);
  const bannerRef = useRef(null);

  useLayoutEffect(() => {
    let element = bannerRef?.current;

    if (element) {
      const isElementInView = () => {
        if (hasElementExitsViewPort(element)) {
          setAnimation(true);
        }
      };

      window.addEventListener("scroll", isElementInView);
      return () => {
        setAnimation(false);
        window.removeEventListener("scroll", isElementInView);
      };
    }
  }, [setAnimation]);

  return (
    <div
      className="row align-items-stretch justify-content-between gx-0"
      ref={bannerRef}
    >
      <div className="col-12">
        <div className="card border-0">
          <div
            className={`card-header overflow-hidden heading-page-banner-image ${className}`}
            style={{ backgroundImage: `url(${bannerImage})` }}
          >
            <div className="position-absolute start-0 w-100 mt-2 heading-position">
              <CustomizeHeading
                text={text}
                gradient={gradient}
                textStyle={"h1"}
              />
            </div>
          </div>
        </div>
      </div>
      {children ? children : null}
    </div>
  );
};

export const AboutBanner = ({ screen = null }) => {
  const { setAnimation } = useContext(AnimationContext);
  const { aboutPageData } = useContext(AppContext);
  const bannerRef = useRef(null);

  useLayoutEffect(() => {
    let element = bannerRef?.current;

    if (element) {
      const isElementInView = () => {
        if (hasElementExitsViewPort(element)) {
          setAnimation(true);
        }
      };

      window.addEventListener("scroll", isElementInView);
      return () => {
        setAnimation(false);
        window.removeEventListener("scroll", isElementInView);
      };
    }
  }, [setAnimation]);

  if (!aboutPageData) return null;

  return (
    <>
      <div
        className="container-xl container-fluid"
        style={{ paddingTop: 100, paddingBottom: 100 }}
      >
        <div className="row align-items-center px-md-5 px-2" ref={bannerRef}>
          <div className="col-lg-5">
            <h1
              className={`text-dark fs-obviously-extended paragraph-lineHeight-40 ${
                screen?.width <= 567 ? "h3" : "h2"
              }`}
            >
              {aboutPageData.headerTitle}
            </h1>
          </div>
          <div className="col-lg">
            <p
              className={`text-dark paragraph-lineHeight-36 ${
                screen?.width <= 567 ? "fs-5" : "fs-4"
              }`}
            >
              {aboutPageData.headerDescription}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
