import React, { useEffect, useRef, useState } from 'react';

import ReactPlayer from 'react-player/lazy';
import { Waypoint } from 'react-waypoint';

import './style.css';

const BackgroundVideoPlayer = ({ startFrom, stop = undefined, ...props }) => {
  const [shouldPlay, updatePlayState] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef !== null) {
      let video = videoRef?.current || undefined;
      if (video && stop !== undefined) {
        updatePlayState(!stop);
      }
    }
  }, [stop]);

  const handleEnterViewport = () => {
    setTimeout(() => {
      updatePlayState(true);
    }, 300);
  };

  const handleExitViewport = () => {
    updatePlayState(false);
  };

  const onProgress = () => {
    if (!videoRef?.current) return;

    const videoNode = videoRef.current.wrapper.querySelector('video');
    const { currentTime, duration } = videoNode;

    const prevProgress = +window.localStorage.getItem('hp_video_engagement_progress_muted');
    const nextProgress = ((currentTime / duration) * 100).toFixed(2);
    if (nextProgress > prevProgress) window.localStorage.setItem('hp_video_engagement_progress_muted', nextProgress);
  };

  return (
    <Waypoint bottomOffset={500} topOffset={500} onEnter={handleEnterViewport} onLeave={handleExitViewport}>
      <div className="player-wrapper homepage-video-muted">
        <ReactPlayer
          ref={videoRef}
          className={'disable-user-events-on-images react-player'}
          playing={shouldPlay}
          playsinline
          muted
          onProgress={onProgress}
          {...props}
        />
      </div>
    </Waypoint>
  );
};

export default BackgroundVideoPlayer;
