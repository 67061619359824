import React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { FullDate } from "./JSFunctions";

export const parsePolicy = ({ name, dateOfUpdate, description }) => {
  const optionsTable = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => children,
      [BLOCKS.TABLE_CELL]: (node, children) => children,
      [BLOCKS.LIST_ITEM]: (node, children) => children,
      [BLOCKS.OL_LIST]: (node, children) => children,
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="mx-0"
          rel="noopener noreferrer"
          target="_blank"
          href={node.data.uri}
        >
          {children}
        </a>
      ),
    },
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <p className="card-subtitle mb-3 mt-5 fw-bold text-decoration-underline">
          {children}
        </p>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="card-text mh-3">{children}</p>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="card-text mb-5 ps-4">
          {documentToReactComponents(node, {
            renderNode: {
              ...optionsTable.renderNode,
              [BLOCKS.LIST_ITEM]: (node, children) => (
                <li style={{ listStyleType: "upper-latin" }}>
                  {documentToReactComponents(node, optionsTable)}
                </li>
              ),
            },
          })}
        </ol>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="card-text mb-5 ps-3">{children}</ul>
      ),
      [BLOCKS.TABLE]: (node, children) => (
        <div className="table-responsive mb-5">
          <table className="table table-bordered align-middle table-nowrap">
            <tbody>{children}</tbody>
          </table>
        </div>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
        <th> {documentToReactComponents(node, optionsTable)}</th>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <td className="description">
          {documentToReactComponents(node, optionsTable)}
        </td>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="mx-0"
          rel="noopener noreferrer"
          target="_blank"
          href={node.data.uri}
        >
          {children}
        </a>
      ),
    },
    renderText: (text) => text.replace("!", "?"),
  };

  return (
    <>
      <h1 className="fs-5 fw-bold card-title" tabIndex={-1}>
        {name}
      </h1>
      <p className="fw-normal text-secondary card-subtitle mb-5">
        Last updated {FullDate(dateOfUpdate)}
      </p>
      {documentToReactComponents(description, options)}
    </>
  );
};
