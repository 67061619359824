import React, { useCallback, useLayoutEffect, useRef, useState } from "react";

import { useWindowSize } from "../../hooks/screen";

const MainComponent = ({
  title,
  description,
  imageSource,
  imageTitle,
  difference = 0,
  isReversed = false,
  isWrapped = false,
}) => {
  const imageRef = useRef();
  const screen = useWindowSize();
  const [lineHeight, setLineHeight] = useState(0);

  const handleLoaded = useCallback(() => {
    if (imageRef.current) {
      const diff =
        screen.width <= 991 && screen.width >= 992 ? difference : difference;
      setLineHeight(imageRef.current.offsetHeight - diff);
    }
  }, [imageRef, screen, difference]);

  useLayoutEffect(() => {
    handleLoaded();
  }, [screen.width, handleLoaded]);

  return (
    <div
      className="row justify-content-between align-items-stretch"
      onLoad={handleLoaded}
    >
      <div
        className={`col-xs-8 pe-0 ps-4 ${
          screen.width > 768 ? "d-block d-md-none" : "d-block"
        }`}
      >
        <h2
          className={`mb-4 text-wrap fs-obviously-wide text-start ${
            screen.width <= 1440 ? "h3" : "h2"
          }`}
          style={{
            width: screen.width >= 480 ? "100%" : 250,
          }}
        >
          {" "}
          {title}{" "}
        </h2>
        <img
          src={imageSource}
          ref={imageRef}
          className="img-fluid"
          alt={imageTitle}
          style={{ width: screen.width >= 600 ? 700 : 500 }}
        />
        <p
          className="fw-normal text-dark mt-4 me-4 text-start"
          style={{ fontSize: screen.width <= 1440 ? 16 : 18 }}
        >
          {description}
        </p>
      </div>
      {isReversed ? (
        <div
          className={`col-12 px-0 ${
            screen.width > 768 ? "d-none d-md-block" : "d-none"
          }`}
        >
          <div className="row align-items-center">
            <div className={`${screen.width <= 990 ? "col-6" : "col-7"}`}>
              <img
                src={imageSource}
                ref={imageRef}
                className="img-fluid pe-3"
                alt={imageTitle}
                style={{
                  width:
                    screen.width >= 1400
                      ? "1050px"
                      : screen.width >= 992
                      ? "700px"
                      : "500px",
                }}
              />
            </div>
            <div className="col">
              <div className="d-block float-sm-end me-5">
                <h2
                  className={`mb-auto fs-obviously-wide paragraph-lineHeight-40 ${
                    screen.width <= 1440 ? "h3" : "h2"
                  }`}
                  style={{
                    maxWidth: screen.width <= 480 ? "250" : "300",
                  }}
                >
                  {" "}
                  {title}{" "}
                </h2>
                <div
                  className="my-4 border-start border-secondary h-100"
                  style={{ minHeight: lineHeight }}
                ></div>
                <p
                  className="fw-normal text-dark mt-auto "
                  style={{
                    maxWidth: 600,
                    fontSize: screen.width <= 1440 ? 16 : 18,
                  }}
                >
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : isWrapped ? (
        <div
          className={`col-12 px-0 ${
            screen.width > 768 ? "d-none d-md-block" : "d-none"
          }`}
        >
          <img
            src={imageSource}
            ref={imageRef}
            style={{
              shapeOutside:
                screen.width >= 1280 ? "inset(60px)" : "inset(50px)",
              padding:
                screen.width >= 1280
                  ? "65px 0px 60px 60px"
                  : screen.width <= 1280 && screen.width >= 992
                  ? "65px 30px 50px 50px"
                  : "65px 35px 50px 50px",
              width:
                screen.width >= 1400
                  ? "1050px"
                  : screen.width >= 992
                  ? "700px"
                  : "500px",
            }}
            className="img-fluid float-sm-end"
            alt={imageTitle}
          />
          <div className="d-block">
            <h2
              className={`mb-auto fs-obviously-wide paragraph-lineHeight-40 ${
                screen.width <= 1440 ? "h3" : "h2"
              }`}
              style={{
                maxWidth: screen.width <= 480 ? "250" : "300",
              }}
            >
              {" "}
              {title}{" "}
            </h2>
            <div
              className="my-4 border-start border-secondary h-100"
              style={{ minHeight: lineHeight }}
            ></div>
            <p
              className="fw-normal text-dark mt-auto "
              style={{
                maxWidth: 600,
                fontSize: screen.width <= 1440 ? 16 : 18,
              }}
            >
              {description}
            </p>
          </div>
        </div>
      ) : (
        <div
          className={`col-12 px-0 ${
            screen.width > 768 ? "d-none d-md-block" : "d-none"
          }`}
        >
          <div className="row align-items-center">
            <div className="col">
              <div className="d-block float-sm-start me-3">
                <h2
                  className={`mb-auto fs-obviously-wide paragraph-lineHeight-40 ${
                    screen.width <= 1440 ? "h3" : "h2"
                  }`}
                >
                  {" "}
                  {title}{" "}
                </h2>
                <div
                  className="my-4 border-start border-secondary h-100"
                  style={{ minHeight: lineHeight }}
                ></div>
                <p
                  className={`fw-normal text-dark mt-auto`}
                  style={{
                    fontSize: screen.width <= 1440 ? 16 : 18,
                  }}
                >
                  {description}
                </p>
              </div>
            </div>
            <div className={`${screen.width <= 990 ? "col-6" : "col-7"}`}>
              <img
                src={imageSource}
                ref={imageRef}
                className="img-fluid"
                alt={imageTitle}
                style={{
                  width:
                    screen.width >= 1400
                      ? "1050px"
                      : screen.width >= 992
                      ? "700px"
                      : "500px",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainComponent;
