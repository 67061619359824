import React from "react";

import "./style.css";

const CustomizeHeading = ({
  children,
  text = null,
  gradient = "",
  className = "",
  textStyle = "",
}) => {
  return (
    <div className="position-relative">
      <div className="overflow-hidden">
        <div
          className={`position-absolute top-0 start-0 heading_position ${className}`}
        >
          <Heading text={text} gradient={gradient} textStyle={textStyle} />
        </div>
        {children}
      </div>
    </div>
  );
};

const Heading = ({ text = "", gradient = "", textStyle = "h1" }) => {
  return (
    <div className="heading_wrapper position-relative">
      <div
        className="heading_gradient position-absolute end-0 bottom-0 w-100 h-100"
        style={{ backgroundImage: gradient }}
      ></div>
      {textStyle === "h1" ? (
        <h1
          className={`fs-obviously-extended position-relative text-uppercase bg-white shadow-sm heading_text mb-0`}
        >
          {text}
        </h1>
      ) : textStyle === "h2" ? (
        <h2
          className={`fs-obviously-extended position-relative text-uppercase bg-white shadow-sm heading_text mb-0`}
        >
          {text}
        </h2>
      ) : (
        <div
          className={`fs-obviously-extended position-relative text-uppercase bg-white shadow-sm heading_text mb-0`}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomizeHeading;
