import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";

import App from "./App";

import "./index.scss";

if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
}


const APP_WRAPPER = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
ReactDOM.render(APP_WRAPPER, rootElement);
