import React, { useContext } from "react";
import Carousel from "react-multi-carousel";

import { AppContext } from "../../context/app";
import { useWindowSize } from "../../hooks/screen";
import { Button } from "../Button";

import "./style.css";

const PressCarousel = ({
  seeMore = false,
  responsive = {},
  title = null,
  ...props
}) => {
  const { pressPageData } = useContext(AppContext);

  if (!pressPageData) {
    return null;
  }

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return (
      <Button
        onClick={() => onClick()}
        disabled={currentSlide === 0}
        className={
          "position-absolute btn-enhanced-raised custom-press-left btn-enhanced-light"
        }
        cType={"icon"}
        icon={
          <svg width="28" height="28" viewBox="0 0 40 40" fill="none">
            <rect width="28" height="28" />
            <path
              d="M23.1865 10L13.5449 19.6416L23.1865 29.2831"
              stroke="#245AE5"
              strokeWidth="5"
              strokeLinecap="round"
            />
          </svg>
        }
      />
    );
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
      <Button
        onClick={() => onClick()}
        disabled={currentSlide === totalItems - slidesToShow}
        className={
          "position-absolute btn-enhanced-raised custom-press-right btn-enhanced-light"
        }
        cType={"icon"}
        icon={
          <svg width="28" height="28" viewBox="0 0 40 40" fill="none">
            <rect width="28" height="28" />
            <path
              d="M16.8135 10L26.4551 19.6416L16.8135 29.2831"
              stroke="#245AE5"
              strokeWidth="5"
              strokeLinecap="round"
            />
          </svg>
        }
      />
    );
  };

  return (
    <div className="row gx-0 align-items-center justify-content-center">
      {title && (
        <div className="col-12 pt-sm-5 pb-sm-5 pb-0 pt-5 text-center text-uppercase">
          {title}
        </div>
      )}

      <div className="col-12">
        {pressPageData &&
          pressPageData.press.length > 0 &&
          (responsive !== undefined && seeMore === false ? (
            <Carousel
              responsive={responsive}
              removeArrowOnDeviceType={["mobile", "tablet"]}
              renderArrowsWhenDisabled={true}
              customDot={<CustomDot />}
              dotListClass={"mt-5"}
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
              infinite={true}
              draggable={false}
              {...props}
            >
              {pressPageData.press.slice(0, 7).map((val) => {
                return (
                  <div
                    className={`press-wrapper ${
                      !val.logo ? "press-wrapper-carousel-no-image" : ""
                    }`}
                    key={val.id}
                  >
                    <PressCard press={val} wrapImage={true} />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <div className="container-fluid px-sm-0 px-3">
              <div className="row justify-content-center g-4">
                {pressPageData.press.map((val) => {
                  return (
                    <div
                      className="press-wrapper col-lg-4 col-sm-6 col-12"
                      key={val.id}
                    >
                      <PressCard press={val} hasText={true} />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
      </div>
      {!seeMore && (
        <div className="col-12 py-md-0 py-5 text-center">
          <Button
            className={"btn-enhanced-dark py-3 px-5 my-md-5 my-0"}
            link="/press"
            text="See More"
          />
        </div>
      )}
    </div>
  );
};

const PressCard = ({
  press,
  hasText = false,
  isGray = false,
  wrapImage = false,
  className = "",
}) => {
  const screen = useWindowSize();

  const logo = press?.logo ? (
    <div className="card-header border-0 px-0 pb-0 m-0 press-images overflow-hidden bg-transparent">
      <img
        src={press.logo.file.url}
        className={`card-img-top disable-user-events-on-images ${
          isGray && "grayed--press-image"
        }`}
        alt="Press Logos"
        style={{
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    </div>
  ) : (
    <h2 className={`press-name ${wrapImage ? "carousel-press-name" : ""}`}>
      {press.name}
    </h2>
  );
  return (
    <div
      className={`card border-0 ${
        hasText === false ? (screen.width > 2000 ? "mx-md-5" : "mx-md-4") : ""
      } mx-sm-3 my-5 bg-transparent ${className}`}
    >
      {wrapImage ? (
        <a
          className="link-dark fs-obviously text-decoration-none"
          href={press.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {logo}
        </a>
      ) : (
        logo
      )}
      {hasText && (
        <>
          {press.excerpt && (
            <div className="card-body border-0 bg-transparent px-1">
              <p className="card-text text-center">
                &quot;{press.excerpt}&quot;
              </p>
            </div>
          )}

          <div className="card-body border-0 bg-transparent px-1">
            <a
              className="link-dark fs-obviously"
              href={press.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              Read Article
            </a>
          </div>
        </>
      )}
    </div>
  );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={`${active ? "active" : "inactive"} customize-press-dots`}
      onClick={() => onClick()}
    >
      <button className="btn btn-sm"></button>
    </li>
  );
};

export default PressCarousel;
