import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { SocialMedia } from "../SocialMedia";
import { Button } from "../Button";

import { useWindowSize } from "../../hooks/screen";
import { useVideoModal } from "../../hooks/useVideoModal";
import { CART_URL, SHOP_URL } from "../../const";

import FrameLogo from "./../../assets/icons/frame-abbreviated-logo-white.png";

import "./style.css";

export const Navbar = () => {
  const [toggleHamburger, setHamburgerStatus] = useState(false);
  const FAQ_URL = "https://frame-fitness.gorgias.help/en-US";

  const screen = useWindowSize();

  const onOpenHamburgerMenu = useCallback(() => {
    setHamburgerStatus(true);
  }, []);

  const onCloseHamburgerMenu = useCallback(() => {
    setHamburgerStatus(false);
  }, []);

 
  class Dropdown extends React.Component {
    state = {
      isOpen: false
    };
    styles = {
  
      'background-color': '#000000bf',
      border: 'none',
      'border-radius' : '0px',
      margin : '0px',
      padding : '0px'
    
    }
  
    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  
    render() {
      const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
      return (
        <div className="dropdown" >
          <button
            className="btn text-capitalize nav-link text-light frame-navbar dropdown"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
           
          >
            Watch Video <span class="drop-symbol">{'\u2B9F' }</span>
          </button>
          <div className={menuClass} style={this.styles}>
            <div class="dropdown-content">
              <WorkoutPreview/>
              <SetupTutorial/>
            </div>
          </div>
        </div>
      );
    }
  }

 

  useEffect(() => {
    if (toggleHamburger) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [toggleHamburger]);

 

  if (screen.width >= 992) {
    return (
      
      <NavbarContainer>
           
        <div className="collapse navbar-collapse pe-md-2">
          <ul className="navbar-nav ms-auto align-items-center">

          <Dropdown/>
                  
         
         <li className="nav-item mx-sm-2 mx-1 position-relative">
              <NavLink
                to="/reformer"
                activeClassName={"activeTextLinkReformer activeFrameLink"}
                className="btn text-capitalize nav-link text-light btn-sm frame-navbar"
              >
                the reformer
              </NavLink>
              <div className="underline"></div>
            </li>
            <li className="nav-item mx-2 position-relative">
              <NavLink
                to="/workout"
                activeClassName={"activeTextLinkWorkout activeFrameLink"}
                className="btn text-capitalize nav-link text-light btn-sm frame-navbar"
              >
                workouts
              </NavLink>
              <div className="underline"></div>
            </li>
            <li className="nav-item mx-2 position-relative">
              <NavLink
                to="/about"
                activeClassName={"activeTextLinkAbout activeFrameLink"}
                className="btn text-capitalize nav-link text-light btn-sm frame-navbar"
              >
                about
              </NavLink>
              <div className="underline"></div>
            </li>
            <li className="nav-item mx-sm-2 mx-1 position-relative">
              <NavLink
                to="/blog"
                activeClassName={"activeTextLinkBlog activeFrameLink"}
                exact
                className="btn text-capitalize nav-link text-light btn-sm frame-navbar"
              >
                Blog
              </NavLink>
              <div className="underline"></div>
            </li>
            <li className="nav-item mx-sm-2 mx-1 position-relative">
              <NavLink
                to="/press"
                activeClassName={"activeTextLinkPress activeFrameLink"}
                className="btn text-capitalize nav-link text-light btn-sm frame-navbar"
              >
                Press{" "}
              </NavLink>
              <div className="underline"></div>
            </li>
            <li className="nav-item mx-sm-2 mx-1 position-relative">
              <a
                href={FAQ_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="btn text-capitalize nav-link text-light btn-sm frame-navbar"
              >
                FAQ
              </a>
            </li>
          </ul>
        </div>
      </NavbarContainer>
    );
  }

  
  return (
    <NavbarContainer
      Hamburger={
        toggleHamburger ? (
          <Button
            onClick={onCloseHamburgerMenu}
            cType="icon"
            className="d-block d-lg-none me-3"
            icon={<HamburgerIconClose />}
            transparent={true}
          />
        ) : (
          <Button
            onClick={onOpenHamburgerMenu}
            cType="icon"
            className="d-block d-lg-none me-3"
            icon={<HamburgerIconOpen />}
            transparent={true}
          />
        )
      }
    >
      <nav
        className={`d-flex d-lg-none mobile-nav-menu flex-column ${
          toggleHamburger ? "active" : ""
        }`}
      >
        <ul className="mobile-nav-menu-items overflow-auto mb-0">

        <li className="mobile-nav-text">
           
          <WorkoutPreviewMobile/>
           </li>
           
          <li className="mobile-nav-text">

          <SetupTutorialMobile/>
          </li>         
          
          <li className="mobile-nav-text">
            <NavLink
              to="/reformer"
              activeClassName={"activeTextLinkReformer activeFrameLink"}
              className="btn text-capitalize nav-link text-light btn-sm frame-navbar position-relative"
              onClick={onCloseHamburgerMenu}
            >
              the reformer
              <span className="mobile-nav-underline"></span>
            </NavLink>
          </li>
          <li className="mobile-nav-text">
            <NavLink
              to="/workout"
              activeClassName={"activeTextLinkWorkout activeFrameLink"}
              onClick={onCloseHamburgerMenu}
              className="btn text-capitalize nav-link text-light btn-sm frame-navbar position-relative"
            >
              workouts
              <span className="mobile-nav-underline"></span>
            </NavLink>
          </li>
          <li className="mobile-nav-text">
            <NavLink
              to="/about"
              activeClassName={"activeTextLinkAbout activeFrameLink"}
              onClick={onCloseHamburgerMenu}
              className="btn text-capitalize nav-link text-light btn-sm frame-navbar position-relative"
            >
              about
              <span className="mobile-nav-underline"></span>
            </NavLink>
          </li>
          <li className="mobile-nav-text">
            <NavLink
              to="/blog"
              activeClassName={"activeTextLinkBlog activeFrameLink"}
              exact
              onClick={onCloseHamburgerMenu}
              className="btn text-capitalize nav-link text-light btn-sm frame-navbar position-relative"
            >
              Blog
              <span className="mobile-nav-underline"></span>
            </NavLink>
          </li>
          <li className="mobile-nav-text">
            <NavLink
              to="/press"
              activeClassName={"activeTextLinkPress activeFrameLink"}
              onClick={onCloseHamburgerMenu}
              className="btn text-capitalize nav-link text-light btn-sm frame-navbar position-relative"
            >
              Press
              <span className="mobile-nav-underline"></span>
            </NavLink>
          </li>
          <li className="mobile-nav-text">
            <a
              href={FAQ_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn text-capitalize nav-link text-light btn-sm frame-navbar position-relative"
            >
              FAQ
            </a>
          </li>

          {screen.width < 768 && (
            <li className="mobile-nav-text mt-5 d-flex d-md-none">
              <SocialMedia
                innerClass="me-lg-3 me-sm-4 me-3 d-block"
                size={screen.width >= 600 ? "28px" : "24px"}
              />
            </li>
          )}
        </ul>
      </nav>
    </NavbarContainer>
  );
};


const WorkoutPreview = () => {
  const { ModalReactPlayer, onOpenVideoModal, isPlaying, onStart, open } =
    useVideoModal(
      "https://frame-fitness-excercise-videos.s3.amazonaws.com/FRAME_WORKOUT_PREVIEW.mp4"
    );

  return (
    <>
      
      <button
        onClick={onOpenVideoModal}
        className="btn"
      >
        <button
          className="text-capitalize text-light  text-capitalize workout-preview-button "
    
        >Workout Preview</button>
      </button>
      
      
  <ModalReactPlayer isPlaying={isPlaying} onStart={onStart} open={open} />
    </>
  );
};

const WorkoutPreviewMobile = () => {
  const styles = {
    'border-color': 'white',
    'border-style': 'solid',
    'border-radius': '7px',
    'border-width': '2px'
     
    };
  
    
  const { ModalReactPlayer, onOpenVideoModal, isPlaying, onStart, open } =
    useVideoModal(
      "https://frame-fitness-excercise-videos.s3.amazonaws.com/FRAME_WORKOUT_PREVIEW.mp4"
    );

  return (
    <>
    <div style={styles}>

      
      <Button
        onClick={onOpenVideoModal}
        text="Preview a Workout"
        className=" text-capitalize nav-link text-light  frame-navbar position-relative p-0 sidebar-workout-preview"
    
      />
       </div>
  <ModalReactPlayer isPlaying={isPlaying} onStart={onStart} open={open} />
    </>
  );
};

const SetupTutorial = () => {
  const { ModalReactPlayer, onOpenVideoModal, isPlaying, onStart, open } =
    useVideoModal(
      "https://video.dev.framefitness.com/production/FRAME_051723_MELISSA_INTROVIDEO_ASSEMBLEDCLIP_10CC_FINAL_V3.mp4"
    );

  return (
    <>
      
      <div
        onClick={onOpenVideoModal}
        className="text-capitalize setup-tutorial-button text-light btn text-capitalize"
      >Setup Tutorial</div>

      <ModalReactPlayer isPlaying={isPlaying} onStart={onStart} open={open} />
    </>
  );
};

const SetupTutorialMobile = () => {
  const { ModalReactPlayer, onOpenVideoModal, isPlaying, onStart, open } =
    useVideoModal(
      "https://video.dev.framefitness.com/production/FRAME_051723_MELISSA_INTROVIDEO_ASSEMBLEDCLIP_10CC_FINAL_V3.mp4"
    );

  return (
    <>
      <Button
        onClick={onOpenVideoModal}
        text="Watch Setup Tutorial"
        className="btn text-capitalize nav-link text-light btn-sm frame-navbar position-relative p-0"

      />

      <ModalReactPlayer isPlaying={isPlaying} onStart={onStart} open={open} />
    </>
  );
};
const NavbarContainer = ({ Hamburger, children }) => {
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark bg-black py-0 sticky-top`}
    >
      <div className="container-fluid ps-lg-4 ps-md-2 navbar-padding pe-0 flex-nowrap">
        <div className="d-flex align-items-center my-2 me-auto">
          {Hamburger}

          <Link className="navbar-brand" to="/">
            <img src={FrameLogo} alt="site logo" className="navbar-icon" />
          </Link>
        </div>
        {children}
        <a href={CART_URL} className="card-link">
          <CartIcon />
        </a>
        <div className="order-btn">
          <Button
            className={`btn btn-primary btn-preorder btn-sm px-md-5 px-4 h-100 d-flex align-items-center rounded-0`}
            isExternal
            link={SHOP_URL}
            text={"ORDER NOW"}
          />
        </div>
      </div>
    </nav>
  );
};

const CartIcon = () => {
  const strokeColor = "#ffffff";
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="8"
        width="19"
        height="14"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
      <path d="M1 16.1666H20" stroke={strokeColor} strokeWidth="1.5" />
      <path
        d="M16.1998 11.1111V6.7C16.1998 3.55198 13.6478 1 10.4998 1V1C7.35178 1 4.7998 3.55197 4.7998 6.7V11.1111"
        stroke={strokeColor}
        strokeWidth="1.5"
      />
    </svg>
  );
};

const HamburgerIconOpen = () => {
  const strokeColor = "#ffffff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
    >
      <g>
        <path
          stroke={strokeColor}
          fill={strokeColor}
          d="M 23.625 9.625 L 4.375 9.625 C 3.894531 9.625 3.5 9.230469 3.5 8.75 C 3.5 8.269531 3.894531 7.875 4.375 7.875 L 23.625 7.875 C 24.105469 7.875 24.5 8.269531 24.5 8.75 C 24.5 9.230469 24.105469 9.625 23.625 9.625 Z M 23.625 14.875 L 4.375 14.875 C 3.894531 14.875 3.5 14.480469 3.5 14 C 3.5 13.519531 3.894531 13.125 4.375 13.125 L 23.625 13.125 C 24.105469 13.125 24.5 13.519531 24.5 14 C 24.5 14.480469 24.105469 14.875 23.625 14.875 Z M 23.625 20.125 L 4.375 20.125 C 3.894531 20.125 3.5 19.730469 3.5 19.25 C 3.5 18.769531 3.894531 18.375 4.375 18.375 L 23.625 18.375 C 24.105469 18.375 24.5 18.769531 24.5 19.25 C 24.5 19.730469 24.105469 20.125 23.625 20.125 Z M 23.625 20.125 "
        />
      </g>
    </svg>
  );
};

const HamburgerIconClose = () => {
  const strokeColor = "#ffffff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      className="p-1"
    >
      <g>
        <path
          stroke={strokeColor}
          fill={strokeColor}
          d="M 24.476562 2.359375 C 24.632812 2.203125 24.839844 2.113281 25.0625 2.113281 C 25.28125 2.113281 25.492188 2.199219 25.644531 2.355469 C 25.96875 2.675781 25.96875 3.199219 25.640625 3.523438 L 3.523438 25.640625 C 3.199219 25.964844 2.675781 25.972656 2.355469 25.644531 C 2.03125 25.324219 2.03125 24.800781 2.359375 24.476562 Z M 24.476562 2.359375 "
        />
        <path
          stroke={strokeColor}
          fill={strokeColor}
          d="M 2.355469 2.355469 C 2.675781 2.03125 3.199219 2.03125 3.523438 2.359375 L 25.640625 24.476562 C 25.964844 24.800781 25.972656 25.324219 25.644531 25.644531 C 25.324219 25.96875 24.800781 25.96875 24.476562 25.640625 L 2.359375 3.523438 C 2.203125 3.367188 2.113281 3.160156 2.113281 2.9375 C 2.113281 2.71875 2.199219 2.507812 2.355469 2.355469 Z M 2.355469 2.355469 "
        />
      </g>
    </svg>
  );
};