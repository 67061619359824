import React from "react";

import "./style.css";

const SubscriptionForm = ({ container = true, background = true }) => {
  return (
    <div
      className={`${container ? "container" : "container-fluid"}`}
      style={{
        backgroundImage:
          background &&
          "linear-gradient(90.18deg, rgb(237, 238, 242) 0.17%, rgb(238, 228, 221) 99.87%)",
      }}
    >
      <div className="row justify-content-center align-items-center py-md-5 position-relative">
        <div className="col-xxl-5 col-xl-4 col-lg-6 col-md-8 text-center px-md-4">
          <div className="card border-0 rounded-0 bg-transparent">
            <div className={`card-body py-5 px-0`}>
              <h2 className="h5 text-uppercase fs-obviously-wide">
                Stay in touch
              </h2>
              <p className="text-dark mt-5 mb-0">
                Want to stay in the loop? We’ll keep you updated with the latest
                in health and fitness, promotions, and giveaways.
              </p>
              <iframe
                id="iframe-newsletter"
                title="Newsletter Signup Form"
                src="https://cdn.forms-content.sg-form.com/0a1b1b31-7dc6-11ee-ba04-aad05d108273"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionForm;
