import React, { useRef } from "react";
import { Modal } from "react-responsive-modal";

import "react-responsive-modal/styles.css";
import "./style.css";

const ModalExtended = ({
  onClose,
  open,
  children,
  fullScreen = false,
  ...props
}) => {
  const modalContentRef = useRef(null);
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      {...props}
      classNames={{
        modalContainer: `${fullScreen ? "h-auto" : "h-100"}`,
        overlay: "customizeOverlay",
        modal: `p-0 ${fullScreen ? "modal-fullscreen bg-dark" : "modal-xl"}`,
        closeButton: `${
          fullScreen &&
          "customize-close-icon position-absolute btn btn-light btn-labeled btn-mat btn-mat-link-default btn-sm btn-circle p-2"
        }`,
      }}
      initialFocusRef={modalContentRef}
    >
      <div className="modal-body p-0" ref={modalContentRef}>
        {children}
      </div>
    </Modal>
  );
};

export default ModalExtended;
