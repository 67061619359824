export const isEmpty = (obj) => {
  if (obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
  }

  return true;
};

export const FullDate = (date) => {
  const date_obj = new Date(date);
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let dd = date_obj.getDate();
  let mm = months[date_obj.getMonth()];
  let yyyy = date_obj.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  return mm + " " + dd + ", " + yyyy;
};

export const EncodeBase64 = (data) => {
  return Buffer.from(data).toString("base64");
};
export const DecodeBase64 = (data) => {
  return Buffer.from(data, "base64").toString("ascii");
};

export const getTitleCase = (str, separator = " ") => {
  const titleCase = str
    .toLowerCase()
    .split(separator)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(separator);

  return titleCase;
};

export const composeStrings = (strings, className) => (
  <>
    {strings.reduce((acc, string, index) => {
      const container = [...acc];
      if (index) {
        container.push(
          <br key={`br-${string}-${index}`} className={className} />
        );
      }
      return [...container, string];
    }, [])}
  </>
);
