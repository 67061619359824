import { useCallback, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";

import { ModalExtended } from "../components/ModalExtended";
import { useWindowSize } from "./screen";

export const useVideoModal = (url) => {
  const screen = useWindowSize();
  const [open, setOpen] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const playerRef = useRef();

  const onOpenVideoModal = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseVideoModal = useCallback(() => {
    setPlaying(false);

    setOpen(false);
  }, []);

  const onStart = useCallback(() => {
    let video = playerRef?.current;
    if (video && screen.width <= 600) {
      const player = video.getInternalPlayer();
      if (player) {
        if (player.requestFullScreen) {
          // W3C API
          player.requestFullScreen();
        } else if (player.mozRequestFullScreen) {
          // Mozilla current API
          player.mozRequestFullScreen();
        } else if (player.webkitRequestFullScreen) {
          // Webkit current API
          player.webkitRequestFullScreen();
        } else if (player.enterFullscreen) {
          player.enterFullscreen();
        }
      }
    }
  }, [screen.width]);

  const onReady = useCallback(() => {
    setPlaying(true);
  }, []);

  const onProgress = () => {
    if (!playerRef?.current) return;

    const videoNode = playerRef.current.wrapper.querySelector('video');
    const { currentTime, duration } = videoNode;

    const prevProgress = +window.localStorage.getItem('hp_video_engagement_progress_expanded');
    const nextProgress = ((currentTime / duration) * 100).toFixed(2);
    if (nextProgress > prevProgress) window.localStorage.setItem('hp_video_engagement_progress_expanded', nextProgress);
  };


  const ModalReactPlayer = useCallback(
    ({ isPlaying, onStart, open }) => {
      return (
        <ModalExtended
          open={open}
          onClose={onCloseVideoModal}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          fullScreen={true}
        >
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-12 vh-100">
              <ReactPlayer
                url={url}
                playing={isPlaying}
                ref={playerRef}
                width={"100%"}
                height={"100%"}
                controls={true}
                onStart={onStart}
                onReady={onReady}
                onProgress={onProgress}
              />
            </div>
          </div>
        </ModalExtended>
      );
    },
    [onCloseVideoModal, onReady, url]
  );

  return {
    ModalReactPlayer,
    open,
    onOpenVideoModal,
    isPlaying,
    onStart,
  };
};
