import React, { createContext, useState } from "react";

const AnimationProvider = ({ children }) => {
  const [animation, setAnimation] = useState(false);

  return (
    <AnimationContext.Provider
      value={{
        animation,
        setAnimation,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
};

export const AnimationContext = createContext({});
export default AnimationProvider;
