import React, { useContext, useMemo } from "react";

import { useWindowSize } from "../../hooks/screen";
import { HorizontalScroll } from "../../components/HorizontalScroll";
import { CarouselExtended } from "../../components/CarouselExtended";
import { HeadingBanner } from "../../components/Banner";
import { AppContext } from "../../context/app";
import { composeStrings } from "../../helpers/JSFunctions";
import { Button } from "../../components/Button";
import { SHOP_URL } from "../../const";

import "./style.css";

const Workout = () => {
  const { workoutPageData } = useContext(AppContext);
  const screen = useWindowSize();

  const reformers = useMemo(() => {
    if (!workoutPageData) return null;
    return workoutPageData.exploreTheReformer.map((reform) => (
      <HorizontalScroll
        key={reform.id}
        image={reform.image.file.url}
        title={reform.title}
      />
    ));
  }, [workoutPageData]);

  if (!workoutPageData) {
    return null;
  }

  const BenefitedTextLeft = (
    <>
      <BenefitsEveryone
        title={workoutPageData.block3Benefit1Title}
        description={workoutPageData.block3Benefit1Description}
      />

      <BenefitsEveryone
        title={workoutPageData.block3Benefit2Title}
        description={workoutPageData.block3Benefit2Description}
      />
    </>
  );

  const BenefitedTextRight = (
    <>
      <BenefitsEveryone
        title={workoutPageData.block3Benefit3Title}
        description={workoutPageData.block3Benefit3Description}
      />

      <BenefitsEveryone
        title={workoutPageData.block3Benefit4Title}
        description={workoutPageData.block3Benefit4Description}
      />
    </>
  );

  return (
    <>
      <HeadingBanner
        bannerImage={workoutPageData.headerImage.file.url}
        text={composeStrings(
          workoutPageData.header,
          `${screen.width > 2560 ? "d-none" : "d-block"}`
        )}
        gradient={"linear-gradient(0deg, #85A3F1 0%, #FFB69F 100%)"}
        className={"workout-background"}
      >
        <div
          className="col-12 bg-light text-center py-5 px-sm-5 px-3"
          style={{ paddingBottom: "8rem" }}
        >
          <p
            className={`text-dark py-5 px-sm-5 px-3 my-5 fs-obviously-extended ${
              screen.width <= 991
                ? "h6 paragraph-lineHeight-32"
                : "h4 paragraph-lineHeight-40"
            }`}
          >
            {composeStrings(workoutPageData.quote, "d-none d-sm-block")}
          </p>
        </div>
      </HeadingBanner>
      <div
        className="container-fluid"
        style={{
          backgroundImage:
            "linear-gradient(0.31deg, #FFFFFF 0%, #E9EFFC 127.25%)",
        }}
      >
        <div className="row align-items-center py-5 px-md-5 px-4 workout_row">
          <div className="col-lg-6 px-lg-5 my-5">
            <h2 className={"text-dark fs-obviously-extended h3 mb-4"}>
              {workoutPageData.block1Title}
            </h2>
            <p
              className={`text-dark mb-5 ${
                screen.width <= 567 ? "fs-6" : "fs-5"
              }`}
            >
              {workoutPageData.block1Description}
            </p>
          </div>
          <div className="col-lg-6 text-center my-5">
            <img
              className="img-fluid"
              alt={workoutPageData.block1Image.title}
              src={workoutPageData.block1Image.file.url}
            />
          </div>
        </div>
      </div>

      <CarouselExtended link="/reformer" buttonText="explore the reformer">
        {reformers}
      </CarouselExtended>

      <div className="container-fluid studio_experience_bg">
        <div className="container">
          <div
            className="row align-items-stretch"
            style={{ padding: "150px 0" }}
          >
            <div className="col-12 text-center">
              <h2 className={"text-dark fs-obviously-extended h3"}>
                {composeStrings(workoutPageData.block2Title)}
              </h2>
              <p
                className={`mx-sm-5 mx-3 px-md-5 px-2 text-dark my-5 ${
                  screen.width <= 567 ? "fs-6" : "fs-5"
                }`}
              >
                {workoutPageData.block2Description}
              </p>
              <img
                src={workoutPageData.block2Image.file.url}
                alt={workoutPageData.block2Image.title}
                className={"img-fluid w-100"}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{
          backgroundImage:
            "linear-gradient(180deg, #E6F4E4 28.62%, #D9B2FF 82.15%)",
        }}
      >
        <div className="row align-items-stretch" style={{ padding: "150px 0" }}>
          <div className="col-12 text-center">
            <h2 className={"text-dark fs-obviously-extended h3"}>
              {workoutPageData.block3Title}
            </h2>
          </div>
          <div className="col-12">
            <div
              className="container-fluid px-sm-5"
              style={{
                width: `${screen.width > 2560 ? "1820px" : "inherit"}`,
              }}
            >
              <div className="row align-items-stretch justify-content-center px-sm-5 d-lg-none d-flex">
                <div className="col-xl-2 px-0 h6">{BenefitedTextLeft}</div>
                <div className="col-xl-8 px-0">
                  <img
                    src={workoutPageData.block3Image.file.url}
                    alt={workoutPageData.block3Image.title}
                    className={"my-5 img-fluid"}
                  />
                </div>
                <div className="col-xl-2 px-0 h6">{BenefitedTextRight}</div>
              </div>
              <div className="row align-items-stretch justify-content-center px-xl-5 d-lg-flex d-none">
                <div
                  className={`col-12 px-0 position-relative text-center ${
                    screen.width >= 1440 ? "h5" : "h6"
                  }`}
                >
                  <div
                    className="position-absolute top-0 start-0 text-start mt-xl-5 pt-xl-5 mt-4 pt-4"
                    style={{
                      width:
                        screen.width >= 1600
                          ? 500
                          : screen.width >= 1300
                          ? 400
                          : 300,
                    }}
                  >
                    {BenefitedTextLeft}
                  </div>
                  <img
                    src={workoutPageData.block3Image.file.url}
                    alt={workoutPageData.block3Image.title}
                    className={"img-fluid"}
                    style={{
                      marginTop: "8rem",
                      paddingTop: "5rem",
                    }}
                  />
                  <div
                    className="position-absolute top-0 end-0 text-start mt-xl-5 pt-xl-5 mt-4 pt-4"
                    style={{
                      width:
                        screen.width >= 1600
                          ? 500
                          : screen.width >= 1300
                          ? 400
                          : 300,
                    }}
                  >
                    {BenefitedTextRight}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid dark_product_background py-5 position-relative">
        <div
          className="row align-items-center justify-content-center my-auto"
          style={{ padding: "150px 0" }}
        >
          <div className="col-12 text-center">
            <h2 className={"text-light fs-obviously-extended h3"}>
              {workoutPageData.comparisonTitle}
            </h2>
          </div>
          <div
            className={`col-sm-10 pt-5 mt-5 ${
              screen.width > 2000 ? "col-xl-8" : ""
            }`}
          >
            <div
              className="card p-sm-5 px-4 py-5 my-5"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(20px)",
              }}
            >
              <div className="row g-0 align-items-center justify-content-between pb-5">
                <div className="col-xl">
                  <div className="card-body text-light">
                    <h3 className="text-start mb-3 fs-3 card-title fs-obviously-wide paragraph-lineHeight-40">
                      {workoutPageData.frameReformerTitle}
                    </h3>
                    <p className="card-text fs-5">
                      {workoutPageData.frameReformerDescription}
                    </p>
                  </div>
                </div>
                <div
                  className="col-auto text-center d-xl-block d-none"
                  style={{
                    transform: "rotate(90deg)",
                    width: 200,
                  }}
                >
                  <div className="vs-wrapper">
                    <span className="vs-text text-light">VS</span>
                  </div>
                </div>
                <div className="col-12 text-center d-xl-none d-block">
                  <div className="card-body vs-wrapper">
                    <span className="text-light">VS</span>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="card-body text-light">
                    <h3 className="text-start mb-3 fs-5 card-title fs-obviously-wide paragraph-lineHeight-40">
                      {workoutPageData.traditionalPilatesTitle}
                    </h3>
                    <p className="card-text fs-5">
                      {workoutPageData.traditionalPilatesDescription}
                    </p>
                  </div>
                </div>
                <div
                  className="col-12 text-center px-lg-5 px-md-3 px-sm-0 position-relative"
                  style={{
                    marginBottom: "-12rem",
                    zIndex: 1,
                  }}
                >
                  <Button
                    className={`btn-enhanced-primary py-3 px-5 w-100`}
                    link={SHOP_URL}
                    isExternal
                    text={"ORDER NOW"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BenefitsEveryone = ({ description = "", title = "" }) => {
  return (
    <div className={`text-dark my-5`}>
      <h3 className="fw-bold d-block fs-5">{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Workout;
