import React, { useEffect } from "react";

const TextExplosion = ({
  text = "Animation Text",
  startAnimation = false,
  reference = null,
  toggle = false,
}) => {
  useEffect(() => {
    const element = reference?.current;
    if (element) {
      const lettersSpan = element.childNodes && [...element?.childNodes];
      const getSlope = () =>
        Math.floor(Math.random() * 100) * (Math.round(Math.random()) ? 1 : -1);
      const getSide = () => 140 * (Math.round(Math.random()) ? 1 : -1);
      if (lettersSpan && lettersSpan.length) {
        lettersSpan.forEach((span, index) => {
          const newTop = index % 2 ? getSlope() : getSide();
          const newLeft = index % 2 ? getSide() : getSlope();

          const css = "top:" + newTop + "%; left:" + newLeft + "%";

          if (startAnimation) {
            span.setAttribute("style", css);
          } else {
            if (span.hasAttribute("style")) span.removeAttribute("style");
          }
        });
      }
    }
  }, [startAnimation, reference]);

  return (
    <>
      {text.split("").map((c, index) => (
        <span className={`explode-text`} key={`${index}-${c}`}>
          {c}
        </span>
      ))}
    </>
  );
};

export default TextExplosion;
