import React from "react";

const GradientBorder = ({ width = 50, className = "" }) => {
  return (
    <div
      className={`overflow-hidden position-absolute top-0 end-0 bottom-0 ${className}`}
    >
      <svg
        width={width}
        height="6552"
        viewBox={`0 0 ${width} 6552`}
        fill="none"
      >
        <rect
          y="3096.13"
          width="1525.18"
          height="59.9999"
          transform="rotate(-90 0 3096.13)"
          fill="url(#paint0_linear)"
        />
        <rect
          y="4846.95"
          width="1750.82"
          height="59.9998"
          transform="rotate(-90 0 4846.95)"
          fill="url(#paint1_linear)"
        />
        <rect
          y="6552"
          width="1705.05"
          height="59.9998"
          transform="rotate(-90 0 6552)"
          fill="url(#paint2_linear)"
        />
        <rect
          y="1576.27"
          width="1576.27"
          height="59.9999"
          transform="rotate(-90 0 1576.27)"
          fill="url(#paint3_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="5.44593e-08"
            y1="3129.67"
            x2="1525.18"
            y2="3129.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DFFF61" />
            <stop offset="1" stopColor="#D9B2FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="0"
            y1="4880.49"
            x2="1750.82"
            y2="4880.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#85A3F1" />
            <stop offset="1" stopColor="#FFB69F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="1.2516e-05"
            y1="6585.54"
            x2="1705.05"
            y2="6585.54"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFCAB9" />
            <stop offset="1" stopColor="#99DBAF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="1.15707e-05"
            y1="1609.81"
            x2="1576.27"
            y2="1609.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFCAB9" />
            <stop offset="1" stopColor="#99DBAF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default GradientBorder;
