import React from "react";
import Routes from "./Routes";

import { ScrollToTop } from "./components/ScrollToTop";
import ApplicationProvider from "./context/app";
import AnimationProvider from "./context/animationContext";
import ExperimentsProvider from './experiments/context/Provider';

const App = () => {
  return (
    <ApplicationProvider>
      <AnimationProvider>
        <ScrollToTop>
          <ExperimentsProvider>
            <Routes />
          </ExperimentsProvider>
        </ScrollToTop>
      </AnimationProvider>
    </ApplicationProvider>
  );
};

export default App;
