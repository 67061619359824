import React from "react";

import MadeForMotion from "./MadeForMotion";
import { SocialMedia } from "../SocialMedia";

import FrameIcon from "../../assets/icons/frame-logo.png";

import "./style.css";

const SideElements = ({ children }) => {
  return (
    <div className="position-relative">
      <div className="left-side-elem position-fixed d-none d-md-block">
        <SocialMedia
          className="justify-content-between bg-white px-1 flex-row-reverse"
          color="black"
          innerClass="my-sm-1 mx-1 left-side-icon"
        />
      </div>

      <div className="right-side-elem position-fixed">
        <div className="d-flex align-items-center flex-row-reverse">
          <img
            src={FrameIcon}
            alt="Frame Logo"
            className="m-0 mx-5 right-logo img-fluid"
          />
          <div className="right-text">
            <MadeForMotion />
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 d-flex flex-column min-vh-100">
        {children}
      </div>
    </div>
  );
};

export default SideElements;
