import React, { useContext, useMemo, useEffect } from 'react';
import { FaPlay } from 'react-icons/fa';

import { useWindowSize } from '../../hooks/screen';
import { useVideoModal } from '../../hooks/useVideoModal';

import { AppContext } from '../../context/app';
import { composeStrings } from '../../helpers/JSFunctions';

import { ProductCompare } from '../../components/ProductCompare';
import { BackgroundVideoPlayer } from '../../components/BackgroundVideoPlayer';
import { Button } from '../../components/Button';

import { MainBanner } from '../../components/Banner';
import { HorizontalScroll } from '../../components/HorizontalScroll';
import { CarouselExtended } from '../../components/CarouselExtended';
import { CustomizeHeading } from '../../components/Heading';
import { PressCarousel } from '../../components/PressCarousel';
import { ShopNow } from '../../components/ShopNow';

import FrameIntroduction from '../../assets/videos/FrameFitness.mp4';
import FramePeopleBG from '../../assets/images/melissa_&_lee_bg.jpg';

import './style.css';
import { ExperimentsContext } from '../../experiments/context';

const reformerDataExp007 = [
  {
    id: 0,
    image: 'https://conversionadvocates.com/exp-images/frf/007/slide-1.jpg',
    title: 'Compact Design, Maximum Convenience',
    description:
      'Our foldable Pilates Reformer fits seamlessly into small spaces. Perfect for urban living or your designated gym space with a fold-and-store design. Dimensions: 94" L x 25.5" W x 13" H.',
  },
  {
    id: 1,
    image: '//images.ctfassets.net/vu25xyvr5gnb/1K7dq9KGJPQKEFXvtJzANr/d5009b78bc35bbc7d5ff8d41191945aa/D1.jpg',
    title: 'Effortless Resistance Adjustment',
    description:
      'Elevate your workout with our push-button technology. Forget manual spring adjustments—enjoy easy, precise resistance control at your fingertips.',
  },
  {
    id: 2,
    image: 'https://conversionadvocates.com/exp-images/frf/007/slide-3.jpg',
    title: 'Unparalleled Strength and Mobility',
    description:
      'Durability meets portability in our top-quality Frame Reformer. With an easy-to-use carrying handle, it’s as mobile as it is robust.',
  },
  {
    id: 3,
    image: 'https://conversionadvocates.com/exp-images/frf/007/slide-4.jpg',
    title: 'Personal Pilates Studio, At Home',
    description:
      'Custom-tailored workouts await. Filter by method, duration, target areas, difficulty level, and equipment. Our system evolves with your fitness journey, ensuring a personalized experience.',
  },
  {
    id: 4,
    image: '//images.ctfassets.net/vu25xyvr5gnb/6n6E5G0kIxeHoLMbQRI6a2/c07dbeebea1f21102aef73bddb6050d6/D2.jpg',
    title: 'Transformative Touchscreen Technology',
    description:
      'Our 24" collapsible, Bluetooth-enabled touchscreen revolutionizes your workouts. Enjoy its 180° swivel feature for diverse exercises, and the ability to fold down the screen for neat storage.',
  },
];

const Home = () => {
  const { homePageData } = useContext(AppContext);
  const screen = useWindowSize();
  const { ModalReactPlayer, onOpenVideoModal, open, isPlaying, onStart } = useVideoModal(FrameIntroduction);

  const reformersExp007 = reformerDataExp007.map((item) => <HorizontalScroll key={item.id} {...item} isExp007={true} />);
  const frf007Id = '100462802';
  const experiments = useContext(ExperimentsContext);
  const isExp007 = experiments[frf007Id].activeVariation === 1;

  const reformers = useMemo(() => {
    if (!homePageData) return null;
    return homePageData.exploreTheReformer.map((reform) => (
      <HorizontalScroll key={reform.id} image={reform.image.file.url} title={reform.title} description={reform.description} />
    ));
  }, [homePageData]);

  useEffect(() => {
    window.localStorage.removeItem('hp_video_engagement_progress_muted');
    window.localStorage.removeItem('hp_video_engagement_progress_expanded');

    window.addEventListener('beforeunload', function () {
      const events = ['hp_video_engagement_progress_muted', 'hp_video_engagement_progress_expanded'];
      const milestones = [10, 25, 50, 75, 100];
      const offsetSec = 3;
      const dataLayerObjects = events
        .map((event) => {
          const progress = window.localStorage.getItem(event);
          if (progress === undefined || +progress < 10) return null;

          const dataLayerObj = { event };
          const prefix = `progress_${event.split('_').pop()}`;
          for (let milestone of milestones) {
            if (progress < milestone - offsetSec) break;

            dataLayerObj[`${prefix}_${milestone}`] = 1;
          }
          return dataLayerObj;
        })
        .filter(Boolean);

      dataLayerObjects.forEach((obj) => window.dataLayer.push(obj));
    });
  }, []);

  if (!homePageData) return null;

  const pressLogosResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: Infinity, min: 1440 },
      items: 8,
    },
    desktop: {
      breakpoint: { max: 1439, min: 992 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 991, min: 600 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: screen.width < 425 ? 1 : 2,
    },
  };

  const FramePeopleElement = (
    <>
      <h2 className="text-start fs-3 card-title fs-obviously-extended">{homePageData.authorsTitle}</h2>
      <p className="h6 card-text my-5 text-start paragraph-lineHeight-26">{homePageData.authorsDescription}</p>

      <Button className={'btn-enhanced-dark w-100 py-3 px-5'} link={'/about'} text={'about'} />
    </>
  );

  return (
    <>
      <div className="row gx-0 align-items-stretch justify-content-center">
        <div className="col-12">
          <div className="position-relative">
            <BackgroundVideoPlayer
              url={homePageData.heroVideo.file.url}
              loop
              width={'100%'}
              height={'100%'}
              stop={open}
              startFrom={0.0732489898887765}
            />
            <Button
              cType="icon"
              onClick={onOpenVideoModal}
              className={'position-absolute translate-middle top-50 start-50 header-video-play-btn'}
              style={{
                lineHeight: '0',
              }}
              transparent={true}
              size="lg"
              icon={<FaPlay size={'1.75em'} color="#fafafa" />}
            />
          </div>
          <ModalReactPlayer isPlaying={isPlaying} onStart={onStart} open={open} />
        </div>
      </div>

      {isExp007 && (
        <div className="carousel-new px-sm-5 px-0">
          <h2 className="text-dark fs-obviously-extended h2 px-3 px-sm-0">Redefining Pilates: Beyond Traditional Reformers</h2>
          <CarouselExtended isExp007={true}>{reformersExp007}</CarouselExtended>
        </div>
      )}

      <MainBanner screen={screen} bannerImage={homePageData.heroImage.file.url}>
        <div className="col-12 bg-light">
          <div className={`${screen.width >= 1440 ? 'container-fluid' : 'container'}`}>
            <PressCarousel
              title={<h2 className={`text-dark fs-obviously-extended paragraph-lineHeight-40 ${screen?.width <= 567 ? 'h4' : 'h3'}`}>As seen in</h2>}
              responsive={pressLogosResponsive}
              showDots={screen.width < 1440}
              autoPlay={screen.width < 1440}
              containerClass={`${screen.width >= 1440 && 'justify-content-center'}`}
            />
          </div>
        </div>
        <div className="col-12 bg-black text-center py-5 px-sm-5 px-3" style={{ paddingBottom: '8rem' }}>
          <p
            className={`text-light m-0 mt-5 px-sm-5 px-3 fs-obviously-extended ${
              screen.width <= 991 ? 'h6 paragraph-lineHeight-32' : 'h4 paragraph-lineHeight-40'
            }`}
          >
            {homePageData.tagline}
          </p>
        </div>
      </MainBanner>

      <div className="col-12 bg-black text-center py-5"></div>

      <CustomizeHeading
        text={composeStrings(homePageData.designHeader, `${screen.width > 2560 ? 'd-none' : 'd-block'}`)}
        textStyle={'h2'}
        gradient={'linear-gradient(0deg, #FFCAB9 0%, #99DBAF 100%)'}
      >
        <ProductCompare />
      </CustomizeHeading>

      <div className="row g-0 align-items-stretch justify-content-between">
        <div className="col-12 text-center overflow-hidden">
          <div className="position-relative">
            <BackgroundVideoPlayer url={homePageData.reviewAnimation.file.url} width={'100%'} height={'100%'} />

            <div className="w-100 position-absolute top-50 start-50 translate-middle">
              <h2
                className={`fs-obviously-extended text-capitalized px-md-3 px-4
                                        ${
                                          screen.width <= 1440 && screen.width >= 567
                                            ? 'fs-2 paragraph-lineHeight-40'
                                            : screen.width < 567
                                            ? 'fs-5 paragraph-lineHeight-32'
                                            : 'display-5 paragraph-lineHeight-40'
                                        } position-relative my-0 mx-auto text-center`}
                style={{ letterSpacing: '0.07em' }}
              >
                {homePageData.reviewHeader}
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* Horizontal Scroll Data */}
      {!isExp007 && (
        <CarouselExtended link="/reformer" buttonText="explore the reformer">
          {reformers}
        </CarouselExtended>
      )}

      <div className="col-12 text-center py-5"></div>
      <CustomizeHeading
        text={composeStrings(homePageData.personalizeHeader, `${screen.width > 2560 ? 'd-none' : 'd-block'}`)}
        textStyle={'h2'}
        gradient={'linear-gradient(180deg, #D9B2FF -30.33%, #FF916E 57.02%, #DFFF61 137.38%)'}
      >
        <div
          className="container-fluid"
          style={{
            backgroundImage: 'linear-gradient(0.31deg, #FFFFFF 0%, #E9EFFC 127.25%)',
          }}
        >
          <div className="row align-items-center py-5 px-md-5 px-4 workout_row">
            <div className="col-lg-6 px-lg-5 my-5">
              <p className={`text-dark mb-5 ${screen.width <= 567 ? 'fs-6' : 'fs-5'}`}>{homePageData.personalizeDescription}</p>

              <Button className={`btn-enhanced-dark py-3 px-5 mx-0`} link={'/workout'} text={'discover the workout'} />
            </div>
            <div className="col-lg-6 text-center my-5">
              <img className="img-fluid" alt={homePageData.personalizeImage.title} src={homePageData.personalizeImage.file.url} />
            </div>
          </div>
        </div>
      </CustomizeHeading>

      <div
        className="container-fluid position-relative"
        style={{
          backgroundImage: `url(${FramePeopleBG})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className="row align-items-center px-md-5 px-0 py-5 about-melissa-lee-wrapper"
          style={{
            backgroundImage: screen.width >= 992 ? `url(${homePageData.authorsImage.file.url})` : null,
          }}
        >
          <div className="col-12 about-melissa-lee d-none d-lg-block">
            <div className="card py-5 px-3 my-5 position-absolute top-50 translate-middle-y" style={{ width: '450px', maxWidth: '100%' }}>
              <div className="card-body">{FramePeopleElement}</div>
            </div>
          </div>

          <div className="col-12 text-center d-lg-none d-block">
            <img src={homePageData.authorsImage.file.url} className={`img-fluid`} alt={homePageData.authorsImage.title} />
            <div className="card py-5 px-3 w-100 mb-5">
              <div className="card-body" style={{ marginTop: '-1rem' }}>
                {FramePeopleElement}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomizeHeading
        text={composeStrings(homePageData.yourHealthHeader, `${screen.width > 2560 ? 'd-none' : 'd-block'}`)}
        textStyle={'h2'}
        gradient={'linear-gradient(180deg, #E6F4E4 41.23%, #D9B2FF 113.73%)'}
      >
        <div
          className="container-fluid py-5"
          style={{
            backgroundImage: 'linear-gradient(180deg, #FFFFFF 0%, #E0E0E0 36.17%, #D9B2FF 88.67%)',
          }}
        >
          <div className="row justify-content-between align-items-center min-vh-100 mx-md-5 mx-0 py-4">
            <div className="col-md-6">
              <p className={`fw-normal text-dark m-md-0 mt-5 paragraph-lineHeight-32 ${screen.width <= 991 ? 'fs-6' : 'fs-5'}`}>
                {homePageData.yourHealthDescription}
              </p>
            </div>
            <div className="col-md-6 px-0 py-0 my-0 position-relative">
              <img src={homePageData.yourHealthImage.file.url} className={`img-fluid`} alt={homePageData.yourHealthImage.title} />
            </div>
            <div className="col-12">
              <ShopNow />
            </div>
          </div>
        </div>
      </CustomizeHeading>
    </>
  );
};

export default Home;
