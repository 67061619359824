import React from "react";
import { Link } from "react-router-dom";

import FrameIcon from "../../assets/icons/frame-logo.png";

const BlogNavigation = ({ filter = "" }) => {
  return (
    <div className="row gx-0">
      <div className="col-12 text-center">
        {filter !== "" ? (
          <Link to={`/${filter}-blog`} className="text-decoration-none">
            <h1 className="display-5 fs-obviously-wide text-capitalize fw-bold text-dark d-inline-block">
              {filter} Blog
            </h1>
          </Link>
        ) : (
          <Link to={"/blog"}>
            <img
              src={FrameIcon}
              className="img-fluid blog-logo"
              alt="Frame Logo"
            />
          </Link>
        )}
      </div>
      <div className="col-12">{/* Navigation/Categories */}</div>
    </div>
  );
};

export default BlogNavigation;
