import React, { createContext, useEffect, useState, useCallback } from "react";

import { useContentful } from "../hooks/useContenful";

const ApplicationProvider = ({ children }) => {
  const [openModalSignUp, setOpenModalSignUp] = useState(false);
  const [homePageData, setHomePageData] = useState(null);
  const [globalData, setGlobalData] = useState(null);
  const [shopNowData, setShopNowData] = useState(null);
  const [reformerData, setReformerData] = useState(null);
  const [workoutPageData, setWorkoutPageData] = useState(null);
  const [aboutPageData, setAboutPageData] = useState(null);
  const [pressPageData, setPressPageData] = useState(null);

  const { getAllData } = useContentful();

  useEffect(() => {
    getAllData().then((response) => {
      setHomePageData(response.homePageData);
      setReformerData(response.reformerPageData);
      setShopNowData(response.shopNowData);
      setGlobalData(response.globalData);
      setWorkoutPageData(response.workoutPageData);
      setAboutPageData(response.aboutPageData);
      setPressPageData(response.pressPageData);
    });
  }, [getAllData]);

  const handleCloseModalSignUp = useCallback(() => {
    setOpenModalSignUp(false);
  }, [setOpenModalSignUp]);

  const handleOpenModalSignUp = useCallback(() => {
    setOpenModalSignUp(true);
  }, [setOpenModalSignUp]);

  return (
    <AppContext.Provider
      value={{
        openModalSignUp,
        handleOpenModalSignUp,
        handleCloseModalSignUp,
        homePageData,
        globalData,
        shopNowData,
        reformerData,
        workoutPageData,
        aboutPageData,
        pressPageData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const AppContext = createContext({});
export default ApplicationProvider;
