import React from "react";
import { useWindowSize } from "../../hooks/screen";
import "./style.css";

const HorizontalScroll = ({ image = null, title = "", description = "", isExp007 = false }) => {
  const screen = useWindowSize();

  if (image === null || image === undefined || image === "") {
    return <> Image Not Specified </>;
  } else if (title === null || title === undefined || title === "") {
    return <> Title Not Specified </>;
  } else {
    return (
      <div className="card border-0 bg-transparent">
        <div
          className="position-relative pb-3 ps-3"
          style={{
            backgroundImage: "linear-gradient(0deg, #ACB1C5 0, white 50%)",
          }}
        >
          <img
            src={image}
            className="card-img-top disable-user-events-on-images"
            alt="Hompage Carousel"
          />
          {/* <div className="position-absolute top-50 start-0 w-100" style={{ backgroundImage: 'linear-gradient(4deg, #ACB1C5 0%, #E1E3EC 100%)', height: 228, transform: screen.width <= 576 ? 'translate(-50%, -35%)' : screen.width >= 992 ? 'translate(-50%, 0%)' : 'translate(-50%, -5%)', zIndex: '-1' }}></div> */}
          {/* <div className="position-absolute top-50 start-50 w-100" style={{ backgroundImage: 'linear-gradient(4deg, #ACB1C5 0%, #E1E3EC 100%)', height: 228, transform: screen.width <= 576 ? 'translate(-50%, -35%)' : screen.width >= 992 ? 'translate(-50%, 0%)' : 'translate(-50%, -5%)', zIndex: '-1' }}></div> */}
        </div>
        <div className={`card-body ${isExp007 ? 'py-sm-5 py-4' : 'py-5'} px-sm-5 px-3`}>
          <h2
            className={`card-title text-wrap fs-obviously-extended ${
              screen.width <= 1280
                ? "fs-5 paragraph-lineHeight-32"
                : "fs-4 paragraph-lineHeight-40"
            }`}
          >
            {" "}
            {title}{" "}
          </h2>
          {description && (
            <p
              className={`card-text text-wrap mt-4  ${
                screen.width <= 2000 ? "fs-6" : "fs-5"
              }`}
            >
              {" "}
              {description}{" "}
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default HorizontalScroll;
