import React, { useContext } from "react";
import { AnimationContext } from "../../context/animationContext";
import { AppContext } from "../../context/app";

const MadeForMotion = () => {
  const { globalData } = useContext(AppContext);
  const { animation } = useContext(AnimationContext);

  if (!globalData) {
    return null;
  }

  const parseString = (str) =>
    str.split("").map((letter, index) => (
      <span
        key={`letter-tagline-${index}`}
        className={letter === " " ? "mx-1" : undefined}
        style={{ "--i": index }}
      >
        {letter}
      </span>
    ));

  return (
    <div
      className={`${animation && "wavy"} 
        position-relative text-uppercase text-nowrap small 
        m-0 fs-obviously-extended wavy-text d-flex align-items-center justify-content-center`}
    >
      {parseString(globalData.tagline)}
    </div>
  );
};

export default MadeForMotion;
