import { useEffect } from "react";

const HelmetComponent = ({ metaData = {} }) => {
  useEffect(() => {
    const base = window.location.origin;

    const link = document.head.querySelectorAll(".meta-url");
    const name = document.head.querySelectorAll(".meta-name");
    const title = document.head.querySelectorAll(".meta-title");
    const description = document.head.querySelectorAll(".meta-description");
    const keywords = document.head.querySelectorAll(".meta-keywords");
    const image = document.head.querySelectorAll(".meta-image");
    const canonical = document.head.querySelectorAll(".meta-canonical");
    const type = document.head.querySelectorAll(".meta-type");

    canonical.forEach(function (value) {
      value.setAttribute("href", base + metaData["link"]);
    });

    link.forEach(function (value) {
      value.setAttribute("content", base + metaData["link"]);
    });

    name.forEach(function (value) {
      value.setAttribute("content", metaData["name"]);
    });

    document.title = metaData["title"] || "Frame Fitness | Made For Motion";
    title.forEach(function (value) {
      value.setAttribute("content", metaData["title"]);
    });

    description.forEach(function (value) {
      value.setAttribute("content", metaData["description"]);
    });

    keywords.forEach(function (value) {
      value.setAttribute("content", metaData["keywords"]);
    });

    image.forEach(function (value) {
      value.setAttribute("content", metaData["image"]);
    });

    let pageType = "website";
    if (metaData["type"]) {
      pageType = metaData["type"];
    }

    type.forEach(function (value) {
      value.setAttribute("content", pageType);
    });
  }, [metaData]);

  return null;
};

export default HelmetComponent;
