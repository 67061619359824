import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import "./Button.scss";

const Button = ({
  text = "Button",
  className = "",
  onClick = undefined,
  icon = undefined,
  cType = "button",
  transparent = false,
  size = "",
  link = undefined,
  noRipple = false,
  isExternal = false,
  ...props
}) => {
  return (
    <>
      {icon === undefined ? (
        <EnhancedButton
          link={link}
          className={`btn-enhanced border-radius-0 ${
            transparent ? ` btn-enhanced-flat ${className}` : ` ${className}`
          }`}
          onClick={onClick}
          noRipple={noRipple}
          isExternal={isExternal}
          {...props}
        >
          <span className="button-text-content">{text}</span>
        </EnhancedButton>
      ) : cType === "icon" ? (
        <EnhancedButton
          link={link}
          onClick={onClick}
          className={`btn-enhanced btn-enhanced-action ${
            size !== "" && `btn-enhanced-action-${size}`
          } ${transparent ? `btn-enhanced-flat ${className}` : `${className}`}`}
          noRipple={noRipple}
          isExternal={isExternal}
          {...props}
        >
          <span className="button-icon"> {icon} </span>
        </EnhancedButton>
      ) : (
        <EnhancedButton
          link={link}
          onClick={onClick}
          className={`btn-enhanced border-radius-0 enhanced-icon-button ${
            transparent ? `btn-enhanced-flat ${className}` : `${className}`
          }`}
          noRipple={noRipple}
          isExternal={isExternal}
          {...props}
        >
          <span className="button-icon"> {icon} </span>
          {text && <span className="button-text-content">{text}</span>}
        </EnhancedButton>
      )}
    </>
  );
};

const EnhancedButton = ({
  link,
  children,
  onClick,
  noRipple,
  isExternal = false,
  ...props
}) => {
  const rippleEffects = useRef();

  const handleOnMouseDown = async (event) => {
    await rippleEffects?.current?.handleRippleEffect(event, false);
  };

  const handleOnMouseUp = async (event) => {
    await rippleEffects?.current?.handleRippleEffect(event, true);
  };

  if (link) {
    if (isExternal) {
      return (
        <a
          href={link}
          {...props}
          onClick={onClick}
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
          onMouseLeave={handleOnMouseUp}
        >
          {noRipple === false && <Ripples ref={rippleEffects} />}
          {children}
        </a>
      );
    } else {
      return (
        <Link
          to={link}
          {...props}
          onClick={onClick}
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
          onMouseLeave={handleOnMouseUp}
        >
          {noRipple === false && <Ripples ref={rippleEffects} />}
          {children}
        </Link>
      );
    }
  } else {
    return (
      <button
        {...props}
        onClick={onClick}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        onMouseLeave={handleOnMouseUp}
      >
        {noRipple === false && <Ripples ref={rippleEffects} />}
        {children}
      </button>
    );
  }
};

export const Ripples = forwardRef((props, ref) => {
  const [rippleEffect, setRippleEffect] = useState({
    clicked: false,
    posX: -1,
    posY: -1,
  });

  const [onCleanup, setOnCleanup] = useState(false);

  useEffect(() => {
    let time_out = null;
    if (onCleanup) {
      time_out = setTimeout(() => {
        setRippleEffect({
          posX: -1,
          posY: -1,
          clicked: false,
        });

        setOnCleanup(false);
      }, 300);
    }
    return () => {
      if (onCleanup && time_out) {
        setOnCleanup(false);
        clearTimeout(time_out);
      }
    };
  }, [onCleanup]);

  useImperativeHandle(ref, () => ({
    async handleRippleEffect(event, cleanup = false) {
      const host = event.currentTarget;
      const rect = host.getBoundingClientRect();
      const cursorX = event.clientX;
      const cursorY = event.clientY;

      const fromTop = cursorY - rect.top;
      const fromBottom = rect.height - fromTop;
      const fromLeft = cursorX - rect.left;
      const fromRight = rect.width - fromLeft;

      const requiredDimension = Math.ceil(
        Math.max(fromRight, fromLeft, fromTop, fromBottom)
      );

      const posX = fromLeft - requiredDimension;
      const posY = fromTop - requiredDimension;
      const size = requiredDimension / 4;

      if (cleanup) {
        if (rippleEffect.clicked) {
          setOnCleanup(true);
        }
      } else {
        setRippleEffect({
          clicked: true,
          posX: posX,
          posY: posY,
          size: size,
        });
      }
    },
  }));

  if (rippleEffect.clicked) {
    return (
      <span
        className="ripple"
        style={{
          top: `${rippleEffect.posY}px`,
          left: `${rippleEffect.posX}px`,
          width: `${rippleEffect.size}px`,
          height: `${rippleEffect.size}px`,
        }}
      />
    );
  }

  return <span style={{ display: "none" }}></span>;
});

export default Button;
