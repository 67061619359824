export const hasElementEnteredViewPort = (elem, threshold, mode) => {
  threshold = threshold || 0;
  mode = mode || "visible";

  const rect = elem.getBoundingClientRect();

  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );
  const above = rect.bottom - threshold < 0;
  const below = rect.top - viewHeight + threshold >= 0;

  return mode === "above" ? above : mode === "below" ? below : !above && !below;
};

export const hasElementExitsViewPort = (elem) => {
  const rect = elem.getBoundingClientRect();
  let isBottomVisible = rect.bottom < window.innerHeight && rect.bottom;
  return (
    isBottomVisible !== 0 && isBottomVisible !== false && isBottomVisible < 0
  );
};

export const createScrollStopListener = (element, callback, timeout) => {
  let removed = false;
  let handle = null;
  const onScroll = () => {
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout || 200); // default 200 ms
  };
  element.addEventListener("scroll", onScroll);
  return () => {
    if (removed) {
      return;
    }
    removed = true;
    if (handle) {
      clearTimeout(handle);
    }
    element.removeEventListener("scroll", onScroll);
  };
};
