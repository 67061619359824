import { ExperimentsContext, initialExperimentsState } from '.';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const Provider = (props) => {
  const location = useLocation();
  const [contextValue, setContextValue] = useState(initialExperimentsState);

  useEffect(() => {
    const updateContext = async () => {
      window._conv_q = window._conv_q || [];
      window._conv_q.push(['run', 'true']);

      await sleep(100);

      const experiments = window.convert.currentData?.experiments;

      if (!experiments) return;

      console.log('updating context value...', experiments);

      const syncExperimentData = (id, currentData) => {
        if (!experiments[id]) {
          return { ...currentData, activeVariation: 0 };
        }

        let activeVariation = 0;
        if (experiments[id].variation_id === currentData.variationA) activeVariation = 1;

        return {
          ...currentData,
          activeVariation,
        };
      };

      const nextContextValue = Object.entries(contextValue).reduce((experiments, [id, data]) => {
        experiments[id] = syncExperimentData(id, data);
        return experiments;
      }, {});

      setContextValue(nextContextValue);

      console.log('setting context to...', { nextContextValue });
      clearInterval(interval);
    };

    const interval = setInterval(updateContext, 500);

    return () => {
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <ExperimentsContext.Provider value={contextValue}>{props.children}</ExperimentsContext.Provider>;
};

export default Provider;
