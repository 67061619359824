import { createClient } from "contentful";
import { useCallback } from "react";

const config = {
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_TOKEN_DELIVERY_API,
};

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  config.environment = "develop";
}

//Connect to delivery api
const client = createClient(config);

//Connect to prewiew api
// const client = createClient({
  // space: process.env.REACT_APP_SPACE_ID,
   //accessToken: process.env.REACT_APP_TOKEN_PREVIEW_API,
   //environment: "develop",
   //host: "preview.contentful.com",
 //});

export const useContentful = () => {
  const sanitizedEntriesHomePageData = (entries) => ({
    ...entries.fields,
    designSlider: entries.fields.designSlider.map((img) => img.fields),
    heroImage: entries.fields.heroImage.fields,
    heroVideo: entries.fields.heroVideo.fields,
    authorsImage: entries.fields.authorsImage.fields,
    personalizeImage: entries.fields.personalizeImage.fields,
    reviewAnimation: entries.fields.reviewAnimation.fields,
    exploreTheReformer: entries.fields.exploreTheReformer.map((elem) => ({
      ...elem.fields,
      image: elem.fields.image.fields,
      id: elem.sys.id,
    })),
    yourHealthImage: entries.fields.yourHealthImage.fields,
    personalizeHeader: entries.fields.personalizeHeader.split("\n"),
    designHeader: entries.fields.designHeader.split("\n"),
    yourHealthHeader: entries.fields.yourHealthHeader.split("\n"),
  });

  const sanitizedEntriesReformerPageData = (entries) => ({
    ...entries.fields,
    header: entries.fields.header.split("\n"),
    headerImage: entries.fields.headerImage.fields,
    infoBlockImage: entries.fields.infoBlockImage.fields,
    reformerBlock1Header: entries.fields.reformerBlock1Header.split("\n"),
    reformerBlock1Image: entries.fields.reformerBlock1Image.fields,
    reformerBlock2Header: entries.fields.reformerBlock2Header.split("\n"),
    reformerBlock2Image: entries.fields.reformerBlock2Image.fields,
    reformerBlock3Header: entries.fields.reformerBlock3Header.split("\n"),
    reformerBlock3Image: entries.fields.reformerBlock3Image.fields,
    reformerBlock4Header: entries.fields.reformerBlock4Header.split("\n"),
    reformerBlock4Image: entries.fields.reformerBlock4Image.fields,
    sizeBlockImage: entries.fields.sizeBlockImage.fields,
    videoBlock1BestPilates: entries.fields.videoBlock1BestPilates.fields,
    videoBlock2: entries.fields.videoBlock2.fields,
  });

  const sanitizedEntriesShopNowData = (entries) => ({
    ...entries.fields,
    purchasePriceHeader: entries.fields.purchasePriceHeader.split("\n"),
  });

  const sanitizedEntriesGlobalData = (entries) => ({
    ...entries.fields,
    contactAddress1: entries.fields.contactAddress1.split("\n"),
    contactAddress2: entries.fields.contactAddress2.split("\n"),
    copyright: entries.fields.copyright.split("\n"),
    rules: entries.fields.rules.map((rule) => rule.fields),
  });

  const sanitizedEntriesWorkoutPageData = (entries) => ({
    ...entries.fields,
    block1Image: entries.fields.block1Image.fields,
    block2Image: entries.fields.block2Image.fields,
    block2Title: entries.fields.block2Title.split("\n"),
    block3Image: entries.fields.block3Image.fields,
    exploreTheReformer: entries.fields.exploreTheReformer.map((elem) => ({
      ...elem.fields,
      image: elem.fields.image.fields,
      id: elem.sys.id,
    })),
    header: entries.fields.header.split("\n"),
    headerImage: entries.fields.headerImage.fields,
    quote: entries.fields.quote.split("\n"),
  });

  const sanitizedEntriesAboutPageData = (entries) => ({
    ...entries.fields,
    coFounder1Title: entries.fields.coFounder1Title.split("\n"),
    coFounder2Title: entries.fields.coFounder2Title.split("\n"),
    coFounderImage: entries.fields.coFounderImage.fields,
    investors: entries.fields.investors.map((elem) => ({
      ...elem.fields,
      id: elem.sys.id,
    })),
  });

  const sanitizedEntriesPressPageData = (entries) => ({
    ...entries.fields,
    press: entries.fields.press.map((socialMedia) => ({
      ...socialMedia.fields,
      logo: socialMedia.fields.logo?.fields || null,
      id: socialMedia.sys.id,
    })),
  });

  const getAllData = useCallback(async () => {
    try {
      const entries = await client.getEntries();

      const sanitizedEntries = {
        homePageData: sanitizedEntriesHomePageData(
          entries.items.find(
            (item) => item.sys.contentType.sys.id === "landing"
          )
        ),
        reformerPageData: sanitizedEntriesReformerPageData(
          entries.items.find(
            (item) => item.sys.contentType.sys.id === "reformerPage"
          )
        ),
        shopNowData: sanitizedEntriesShopNowData(
          entries.items.find(
            (item) => item.sys.contentType.sys.id === "shopNow"
          )
        ),
        globalData: sanitizedEntriesGlobalData(
          entries.items.find((item) => item.sys.contentType.sys.id === "global")
        ),
        workoutPageData: sanitizedEntriesWorkoutPageData(
          entries.items.find(
            (item) => item.sys.contentType.sys.id === "workoutPage"
          )
        ),
        aboutPageData: sanitizedEntriesAboutPageData(
          entries.items.find(
            (item) => item.sys.contentType.sys.id === "aboutPage"
          )
        ),
        pressPageData: sanitizedEntriesPressPageData(
          entries.items.find(
            (item) => item.sys.contentType.sys.id === "pressPage"
          )
        ),
      };

      return sanitizedEntries;
    } catch (error) {
      console.error(`Error fetching content: ${error.message}`);
    }
  }, []);

  return {
    getAllData,
  };
};
