import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { useWindowSize } from "../../hooks/screen";
import { SubscriptionForm } from "../SubscriptionForm";
import { SocialMedia } from "../SocialMedia";
import { composeStrings } from "../../helpers/JSFunctions";
import { AppContext } from "../../context/app";

import FrameLogo from "../../assets/icons/frame-abbreviated-logo-white.png";
import "./style.css";

export const Footer = () => {
  const { globalData } = useContext(AppContext);
  const screen = useWindowSize();

  if (!globalData) {
    return null;
  }

  return (
    <>
      <div
        className="py-5"
        style={{
          backgroundImage:
            "linear-gradient(90.18deg, rgb(237, 238, 242) 0.17%, rgb(238, 228, 221) 99.87%)",
        }}
      >
        <SubscriptionForm background={false} />
      </div>
      <div
        className="container-fluid px-10 bg-black position-relative text-light py-5"
        id="scroll-to-footer"
      >
        <div className="row gx-0 align-items-lg-center">
          <div className="col-6">
            <p className="h6 fs-obviously">Contact</p>
            <p className="small fw-normal mb-0 mt-4">
              <a
                className="link link-light text-decoration-none"
                rel="noopener noreferrer"
                target="_blank"
                href="mailto:info@framefitness.com"
              >
                {globalData.contactEmail}
              </a>
            </p>
            <div className="addresses-container">
              <p className="small fw-normal my-2 addresses-container-item">
                {composeStrings(globalData.contactAddress1)}
              </p>
              <p className="small fw-normal my-2">
                {composeStrings(globalData.contactAddress2)}
              </p>
            </div>
          </div>
          <div className="col-6 text-lg-start text-end">
            <img
              src={FrameLogo}
              alt="Site Logo"
              className="img-fluid my-lg-4 mt-0 mb-4 footer-site-icon"
            />
          </div>
        </div>
        <div className="row gx-0 align-items-end justify-content-between mt-1">
          <div className="col-sm">
            <SocialMedia
              innerClass="me-lg-3 me-sm-4 me-3"
              size={screen.width >= 600 ? "28px" : "24px"}
            />
          </div>
          <div className="col-sm text-end mt-sm-0 mt-4">
            <div
              className="row align-items-center justify-content-between"
              style={{
                flexDirection: screen.width <= 576 ? "row-reverse" : "row",
              }}
            >
              <Policy />
              <div className="col col-sm-12 text-sm-end text-start">
                <p
                  className="mb-0"
                  style={{
                    fontSize: screen.width < 425 && "smaller",
                  }}
                >
                  {composeStrings(globalData.copyright)}
                </p>
                <Link
                  to={"/guest-blog"}
                  className="link link-light mb-0 text-capitalize"
                  style={{
                    fontSize: screen.width < 425 && "smaller",
                  }}
                >
                  More Content
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Policy = () => {
  const screen = useWindowSize();

  return (
    <div className="col-auto col-sm-12">
      <Link
        to={"/terms-of-service"}
        className="link link-light text-decoration-underline d-block
        text-capitalize px-0 me-0 mb-sm-3 mb-0 d-sm-inline-block"
        style={{
          fontSize: screen.width < 425 && "smaller",
        }}
      >
        Terms of Service
      </Link>
      <Link
        to={"/privacy-policy"}
        className="link link-light text-decoration-underline d-block
                          text-capitalize px-0 ms-0 ms-sm-2 mb-sm-3 mb-0 d-sm-inline-block"
        style={{
          fontSize: screen.width < 425 && "smaller",
        }}
      >
        Privacy Policy
      </Link>
      <Link
        to={"/return-policy"}
        className="link link-light text-decoration-underline d-block
                          text-capitalize px-0 ms-0 ms-sm-2 mb-sm-3 mb-0 d-sm-inline-block"
        style={{
          fontSize: screen.width < 425 && "smaller",
        }}
      >
        Return Policy
      </Link>
      <Link
        to={"/warranty"}
        className="link link-light text-decoration-underline d-block
                          text-capitalize px-0 ms-0 ms-sm-2 mb-sm-3 mb-0 d-sm-inline-block"
        style={{
          fontSize: screen.width < 425 && "smaller",
        }}
      >
        Warranty
      </Link>
    </div>
  );
};
