import React, { useContext } from "react";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";

import { AppContext } from "../../context/app";
import { Button } from "../Button";

import "./style.css";

const SocialMedia = ({
  className = "",
  color = "white",
  innerClass = "",
  innerClass01 = "",
  innerClass02 = "",
  innerClass03 = "",
  size = "28px",
}) => {
  const { globalData } = useContext(AppContext);

  if (!globalData) {
    return null;
  }

  return (
    <div className={`d-flex align-items-center ${className}`}>
      <Button
        icon={<FaFacebook color={color} size={size} />}
        cType="icon"
        link={globalData.facebook}
        rel="noopener noreferrer"
        className={`btn-icon-height ${innerClass} ${innerClass01}`}
        target="_blank"
        isExternal={true}
        transparent={true}
      />

      <Button
        icon={<FaTiktok color={color} size={size} />}
        cType="icon"
        link={globalData.tiktok}
        rel="noopener noreferrer"
        target="_blank"
        className={`btn-icon-height ${innerClass} ${innerClass02}`}
        transparent={true}
        isExternal={true}
      />

      <Button
        icon={<FaInstagram color={color} size={size} />}
        cType="icon"
        link={globalData.instagram}
        rel="noopener noreferrer"
        target="_blank"
        className={`btn-icon-height ${innerClass} ${innerClass03}`}
        transparent={true}
        isExternal={true}
      />
    </div>
  );
};

export default SocialMedia;
